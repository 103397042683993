import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AppInsightsService } from './app-insights.service';
import { AppService } from './app.service';
import { map, catchError } from 'rxjs/operators';
import { ICallScriptDetails, ICallScriptModel, IGetCallScriptSetting, IListOfValues, IPostResponse, IFields, IStore, IRouteDefinedModel, ICallScriptList } from '../Shared/interface';

@Injectable({
  providedIn: 'root'
})
export class CallScriptService {

  private baseURL = this.appService.config.apiBaseUrl;
  url;
  constructor(private http: HttpClient,
    private appinsights: AppInsightsService,
    private appService: AppService) { }

  getCallScriptDetails(id: number): Observable<ICallScriptDetails> {
    return this.http.get<ICallScriptDetails>(
      `${this.baseURL}/CallerScript/GetCallScriptDetails?callScriptId=${id}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          const callScriptDetails = res.body as ICallScriptDetails;
          return callScriptDetails;
        }),
        catchError(this.handleError)
      );
  }

  getCallScriptList(): Observable<ICallScriptList[]> {
    return this.http.get<ICallScriptList[]>(
      `${this.baseURL}/CallerScript/GetAllCallerScripts`,
      { observe: 'response' })
      .pipe(
        map(res => {
          const callScriptList = res.body as ICallScriptList[];
          return callScriptList;
        }),
        catchError(this.handleError)
      );
  }

  getForms(): Observable<IGetCallScriptSetting[]> {
    return this.http.get<IGetCallScriptSetting[]>(
      `${this.baseURL}/CallerScript/GetForms`)
      .pipe(
        map(result => {
          return result as IGetCallScriptSetting[];
        }),
        catchError(this.handleError)
      );
  }

  getMandatoryFields(formId: number): Observable<IFields[]> {
    return this.http.get<IFields[]>(
      `${this.baseURL}/CallerScript/GetMandatoryFields?formId=${formId}`)
      .pipe(
        map(result => {
          return result as IFields[];
        }),
        catchError(this.handleError)
      );
  }

  getCallScriptsForSections(route, type, facilityId, sectionId) {
    return this.http.get<ICallScriptModel>(
      `${this.baseURL}/CallerScript/GetCallScripts?route=${route}&type=${type}&facilityId=${facilityId}&section=${sectionId}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          const scriptData = res.body as ICallScriptModel;
          return scriptData;
        }),
        catchError(this.handleError)
      );
  }

  GetAllCallScriptDetails(facilityId) {
    return this.http.get<[]>(
      `${this.baseURL}/CallerScript/GetAllCallScriptDetails?facilityId=${facilityId}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          const scriptData = res.body as [];
          return scriptData;
        }),
        catchError(this.handleError)
      );
  }
  
  CheckScriptPresentForRoutes(type, facilityId, sectionId) {
    return this.http.get<IRouteDefinedModel>(
      `${this.baseURL}/CallerScript/CheckScriptPresentForRoutes?section=${sectionId}&type=${type}&facilityId=${facilityId}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          const routeData = res.body as IRouteDefinedModel;
          return routeData;
        }),
        catchError(this.handleError)
      );
  }

  getIsCallScriptSettingNameExist(CallScriptName: string): Observable<boolean> {
    return this.http.get<any>(
      `${this.baseURL}/CallerScript/IsCallScriptNameExist?CallScriptName=${CallScriptName}`,
      { observe: 'response' })
      .pipe(
        map(result => {
          let isExist: boolean;
          const NameExist = result.body as any;
          if ((typeof NameExist === 'undefined') || (NameExist === null)) {
            isExist = false;
            return isExist;
          } else {
            return NameExist;
          }
        }),
        catchError(this.handleError)
      );
  }

  GetIsCallScriptCombinationExists(route: number, type: number, section: number, callCenter: boolean, callScriptId: number): Observable<IPostResponse> {
    return this.http.get<any>(
      `${this.baseURL}/CallerScript/IsCallScriptCombinationExists?route=${route}&type=${type}&section=${section}&callCenter=${callCenter}&callScriptId=${callScriptId}`,
      { observe: 'response' })
      .pipe(
        map(result => {
          let isExist: boolean;
          const FormExist = result.body as any;
          if ((typeof FormExist === 'undefined') || (FormExist === null)) {
            isExist = false;
            return isExist;
          } else {
            return FormExist;
          }
        }),
        catchError(this.handleError)
      );
  }

  saveCallScript(callscript: ICallScriptDetails): Observable<IPostResponse> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    let url = '';
    if (callscript.CallScriptId === 0) {
      url = `${this.baseURL}/CallerScript/CreateCallScript`;
    }
    else {
      url = `${this.baseURL}/CallerScript/UpdateCallScript`;
    }
    return this.http.post<IPostResponse>(url, callscript, options)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  deleteCallScript(CallScriptId: number) {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<IPostResponse>(`${this.baseURL}/CallerScript/DeleteCallScript?CallScriptId=${CallScriptId}`, options);
  }

  getsCallScriptAvailableStores(route, type, section): Observable<IStore[]> {
    return this.http.get<IStore[]>(
      `${this.baseURL}/CallerScript/GetAvailableStores?route=${route}&type=${type}&section=${section}`,
      { observe: 'response' }
    ).pipe(
      map(res => {
        const CallScriptFacilityList = res.body as IStore[];
        return CallScriptFacilityList;
      }),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    console.log('server error:', error);
    if (error.error instanceof Error) {
      const errMessage = error.error.message;
      this.appinsights.logEvent(errMessage);
      return throwError(errMessage);
    }
    return throwError(error || 'Node.js server error');
  }
}

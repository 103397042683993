import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { crc32 } from 'crc';
import { AppService } from './services/app.service';
import { catchError, map } from 'rxjs/operators';
import 'rxjs/add/operator/switchMap';
import {mergeMap} from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class CrcInterceptor implements HttpInterceptor {
  appinsights: any;

   constructor(private appService: AppService,
              private http: HttpClient) {
     
   }
   baseURL: string;
   redisCacheInput;
   pendingReq = [];

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      var reqBody = req.body;
      this.baseURL = this.appService.config.apiBaseUrl;
      var crcData;
      var url = req.url;
      this.redisCacheInput;
      
      if(url.includes(this.baseURL) && !url.includes('General/SetRedisCache')) {
        if (reqBody) {   
            if(!this.isJsonString(reqBody)){
              reqBody = JSON.stringify(reqBody);
            }
            const urlIndex = url.indexOf('?');
            if(urlIndex > -1) {
              const urlWithoutParam = url.substring(0,urlIndex);
              crcData = crc32(url.substring(urlIndex + 1 ,url.length) + reqBody);
              this.redisCacheInput = {
                inputKey: `${url.substring(urlWithoutParam.lastIndexOf('/') + 1, urlIndex)}_${crcData}`,
                inputValue: crcData
              }
            } else {
              crcData = crc32(reqBody)
              this.redisCacheInput = {
                inputKey: `${url.substring(req.url.lastIndexOf('/') + 1, url.length)}_${crcData}`,
                inputValue: crcData
              }
            }
            req = req.clone({
              setHeaders: {
                UniqueId:  `${crcData}`
              }
            });
            // this.pendingReq.push({
            //  originalReq : req.clone({body : JSON.parse(JSON.stringify(req.body))}),
            //  crc: crcData
            // });
            // const uniqueCode  = crc32(JSON.stringify(this.redisCacheInput));
            // return this.setRedisCache().pipe(mergeMap(configData => {
            //   const index = this.pendingReq.findIndex(req => req.crc == configData);
            //   const req  = this.pendingReq[index];
            //   this.pendingReq.splice(index,1);

            //   return next.handle(req.originalReq);
            //   }));

            return next.handle(req);

        } else {
          const reqUrl = req.urlWithParams;
          const urlIndex = reqUrl.indexOf('?');
          if(urlIndex > -1) {
            crcData = crc32(reqUrl.substring(urlIndex + 1 ,reqUrl.length));
            req = req.clone({
              setHeaders: {
                UniqueId:  `${crcData}`
              }
            });
          }
          return next.handle(req);
        }
      } else  {
        return next.handle(req);
      }
    }

    isJsonString(str): boolean {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      if(Array.isArray(str))
        return false;
        
      return true;
    }

    setRedisCache(): Observable<any> {
      const uniqueCode  = crc32(JSON.stringify(this.redisCacheInput));
      const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'UniqueId':`${uniqueCode}`}) };
      let url = `${this.baseURL}/General/SetRedisCache`;
      return this.http.post<any>(url, this.redisCacheInput, options)
      .pipe(
          map(res => {
              return res;
          }),
          catchError(this.handleError)
      );
          
    }

    private handleError(error: HttpErrorResponse) {
      if (error.error instanceof Error) {
          const errMessage = error.error.message;
          this.appinsights.logEvent(errMessage);
          return throwError(errMessage);
      }
      return throwError(error || 'Node.js server error');
  }
    
}
<div *ngIf="loggedInUserDetails">
  <router-outlet></router-outlet>
</div>

<div class="modal fade" bsModal #warning="bs-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" data-keyboard="false">
  <div class="modal-dialog modal-dialog-centered alert_box" role="document">
    <div class="modal-content">
      <div class="alert alert-danger text-center mb-0" role="alert">
        <strong>Warning!</strong>
      </div>
      <div class="modal-body text-center">
        <h6>Day End is currently in progress. <br> Please login after some time!</h6>
        <div class="w-100 mt-3">
          <button class="btn btn-primary w-35" type="button" data-dismiss="modal"
            (click)="onClear('DayEnd')">OK</button>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade" bsModal #geoLocationWarning="bs-modal" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel" aria-hidden="true" data-keyboard="false">
  <div class="modal-dialog modal-dialog-centered alert_box" role="document">
    <div class="modal-content">
      <div class="alert alert-danger text-center mb-0" role="alert">
        <strong>Warning!</strong>
      </div>
      <div class="modal-body text-center">
        <h6>Geolocation needs to be enabled to continue using the application or try from a different browser!</h6>
        <div class="w-100 mt-3">
          <button class="btn btn-primary w-35" type="button" data-dismiss="modal"
            (click)="onClear('GeoLocation')">OK</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #storeNotFound="bs-modal" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered geo_location_alert_box" role="document">
    <div class="modal-content">
      <div class="alert alert-danger text-center pt-2" role="alert">
        <h5><b>Warning!</b></h5>
      </div>
      <div class="modal-body">
        <h5>No Store details are found in the current location, please contact your administrator.</h5>
        <div class="w-100 mt-4 pt-3 text-center">
          <button class="btn btn-primary w-35" type="button" data-dismiss="modal"
            (click)="onClear('GeoLocation')">OK</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #noStoresFound="bs-modal" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered geo_location_alert_box" role="document">
    <div class="modal-content">
      <div class="alert alert-danger text-center pt-2" role="alert">
        <h5><b>Warning!</b></h5>
      </div>
      <div class="modal-body">
        <h5>Oops..You don't have any stores assigned, Please contact Administrator for more details.</h5>
        <div class="w-100 mt-4 pt-3 text-center">
          <button class="btn btn-primary w-35" type="button" data-dismiss="modal"
            (click)="onClear('GeoLocation')">OK</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #authenticationError="bs-modal" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-md modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="alert alert-danger text-center pt-2" role="alert">
        <h5><b>Warning!</b></h5>
      </div>
      <div class="modal-body">
        <h5 class="text-center">User set-up is incorrect, Please contact Administrator.</h5>
        <div class="w-100 mt-4 pt-3 text-center">
          <button class="btn btn-primary" type="button" data-dismiss="modal"
            (click)="onClear('authenticationError')">OK</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #appNewVersion="bs-modal" tabindex="-1" role="dialog"
  aria-labelledby="New Application Version Available" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">New version available!</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="appNewVersion.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Click 'Update now!' to upgrade to a newer version or 'Close' to keep using current version of the app.
        <div class="w-100 mt-4 pt-3 text-center">
          <button class="btn w-25 mr-2" type="button" data-dismiss="modal" (click)="appNewVersion.hide()">Close</button>
          <button class="btn btn-primary w-35 ml-2" type="button" data-dismiss="modal"
            (click)="updateAppVersion()">Update now!</button>
        </div>
      </div>
    </div>
  </div>
</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { MasterComponent } from './master/master.component';
import { RouterDefaultGuard } from './Shared/routing.guard';
import { MsalGuard } from '@azure/msal-angular';
import { CustomPreloadingStrategy } from './Shared/custom-preloading';
import { ErrorPageComponent } from './Shared/ErrorPage/ErrorPage.component';
import { UserloginComponent } from './Shared/user-login/user-login.component';

const appRoutes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('./admin/adm-dashboard/adm.module').then(m => m.AdmModule),
  },
  {
    path: 'prospect',
    loadChildren: () => import('./facilityOperations/facOps-prospect/facops.module').then(m => m.FacilityOperationsModule),
    canActivate: [MsalGuard],
    data: { preload: true, delay: 4000 }
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./facilityOperations/facOps-dashboard/facOps-dashboard.module').then(m => m.FacOpsDashboardModule),
     canActivate: [MsalGuard],
    data: { preload: true, delay: 100 }
  },
  {
    path: 'customer',
    loadChildren: () => import('./facilityOperations/facOps-customer/facops-customer.module').then(m => m.FacilityOpsCustomerModule),
    canActivate: [MsalGuard],
    data: { preload: true, delay: 3000 }
  },
  {
    path: 'facimer',
    loadChildren: () => import('./facilityOperations/facOps-merchandise/facOps-merchandise.module').then(m => m.FacilityOpsMerchandiseModule),
   canActivate: [MsalGuard],
    data: { preload: true, delay: 3000 }
  },
  {
    path: 'report',
    loadChildren: () => import('./facilityOperations/facOps-report/facOps-report.module').then(m => m.FacOpsReportModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'facilityAdmin',
    loadChildren: () => import('./facilityOperations/facOps-admin/facOps-admin.module').then(m => m.FacOpsAdminModule),
    canActivate: [MsalGuard],
    data: { preload: true, delay: 4000 }
  },
  {
    path: 'printControl',
    loadChildren: () => import('./facilityOperations/facOps-print-control/facOps-print-control.module').then(m => m.FacOpsPrintControlModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'openClose',
    loadChildren: () => import('./facilityOperations/facOps-open-close/facOps-open-close.module').then(m => m.FacOpsOpenCloseModule),
    canActivate: [MsalGuard]
  },
  // {
  //   path: 'smsEmail',
  //   loadChildren: './facilityOperations/facOps-sms-email-internal-use/facOps-sms-email-internal-use.module#FacOpsSmsEmailInternalUseModule',
  //   canActivate: [MsalGuard]
  // },
  {
    path: 'eSignature',
    loadChildren: () => import('./add-ons/esignature/esignature.module').then(m => m.EsignatureModule),
    canActivate: [MsalGuard],
    data: { preload: false, delay: 4000 }
  },
  {
    path: 'marketingd',
    loadChildren: () => import('./add-ons/marketing/marketing.module').then(m => m.MarketingModule),
    canActivate: [MsalGuard],
    data: { preload: false, delay: 4000 }
  },
  {
    path: 'fi',
    loadChildren: () => import('./admin/adm-storagefac/adm-storagefac.module').then(m => m.AdmSFModule),
    canActivate: [RouterDefaultGuard],
    data: { preload: true, delay: 1000 }
  },
  {
    path: 'us',
    loadChildren: () => import('./admin/adm-security/adm-security-user/adm-security-user.module').then(m => m.UserModule),
    canActivate: [RouterDefaultGuard]
  },
  {
    path: 'security-dashboard',
    loadChildren: () => import('./admin/adm-security/adm-security-dashboard/adm-security-dashboard.module').then(m => m.AdmSecurityDashboardModule),
    canActivate: [RouterDefaultGuard],
    data: { preload: false, delay: 5000 }
  },
  {
    path: 'lateLien-settings',
    loadChildren: () => import('./admin/adm-security/adm-late-and-lien-settings/adm-late-and-lien-settings.module').then(m => m.AdmLateAndLienSettingsModule),
    canActivate: [RouterDefaultGuard],
    data: { preload: false, delay: 5000 }
  },
  {
    path: 'ug',
    loadChildren: () => import('./admin/adm-security/adm-security-ug/adm-security-ug.module').then(m => m.UserGroupsModule),
    canActivate: [RouterDefaultGuard]
  },
  {
    path: 'rgl',
    loadChildren: () => import('./admin/adm-security/adm-security-reportgroup/adm-security-reportgroups.module').then(m => m.ReportGroupModule),
    canActivate: [RouterDefaultGuard]
  },
  {
    path: 'sp',
    loadChildren: () => import('./admin/adm-security/adm-security-panel/adm-security-panel.module').then(m => m.SecurityPanelModule)
  },
  {
    path: 'ss',
    loadChildren: () => import('./admin/adm-systemsetting/adm-systemsetting-dashboard.module').then(m => m.AdmSysSettingsModule),
    data: { preload: false, delay: 5000 }
  },
  {
    path: 'mt',
    loadChildren: () => import('./admin/adm-maintenance/adm-maintenance-dashboard.module').then(m => m.AdmMaintenanceModule),
    data: { preload: false, delay: 5000 }
  },
  {
    path: 'pm',
    loadChildren: () => import('./admin/adm-prospectmanagement/adm-prospectmanagement-dashboard.module').then(m => m.AdmProspectManagementModule),
    data: { preload: false, delay: 5000 }
  },
  {
    path: 'un',
    loadChildren: () => import('./admin/adm-units/adm-units.module').then(m => m.AdmUnitsModule),
    data: { preload: false, delay: 5000 }
  },
  {
    path: 'ac',
    loadChildren: () => import('./admin/adm-accounting/adm-accounting.module').then(m => m.AdmAccountingModule),
    data: { preload: false, delay: 5000 }
  },
  {
    path: 'mk',
    loadChildren: () => import('./admin/adm-marketing/adm-marketing.module').then(m => m.AdmMarketingModule),
    data: { preload: false, delay: 5000 }
  },
  {
    path: 'pr',
    loadChildren: () => import('./admin/adm-pricing/adm-pricing.module').then(m => m.AdmPricingModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./admin/adm-payment/adm-payment.module').then(m => m.AdmPaymentModule)
  },
  {
    path: 'companysettings',
    loadChildren: () => import('./admin/adm-companysetting/adm-companysetting.module').then(m => m.AdmcompanysettingModule)
  },
  {
    path: 'login',
    component: UserloginComponent
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'tdbank',
    component: MasterComponent
  },
  {
    path: 'layouts',
    loadChildren: () => import('./facilityOperations/facOps-layouts/operation-layouts/operation-layouts.module').then(m => m.OperationLayoutsModule),
 //   canActivate: [MsalGuard]
  },
  {
    path: '**',
    component: ErrorPageComponent
  },
];
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes, { preloadingStrategy: CustomPreloadingStrategy })
  ],
  declarations: [],
  exports: [
    RouterModule
  ],
  providers: [
    RouterDefaultGuard,
    CustomPreloadingStrategy
  ]
})
export class AppRoutingModule { }

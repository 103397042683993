import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable()

export class RouterDefaultGuard {
    private firstNavigation = true;
    private url: any;
    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.firstNavigation) {
            this.url = this.router.navigated;
            this.firstNavigation = false;
            if (!this.url) {
                this.router.navigateByUrl('');
                return false;
            }
        }
        return true;
    }

}

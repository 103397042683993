import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {AppInsightsService} from '../../services/app-insights.service';
import {AppService} from '../../services/app.service';

@Injectable({
    providedIn: 'root'
})
export class UserLoginService {

    constructor(public http: HttpClient,
                private appService: AppService) {

    }

    private baseURL = this.appService.config.apiBaseUrl;

    getUserLogin(userName, password): Observable<any>{
        let options ={};

            options = { headers: new HttpHeaders({
                'Content-Type': 'text/plain; charset=utf-8',
             'userId': `${userName}`,
             'password': `${password}`,
             'responseType': 'text'
             }) };

  let url = `${this.baseURL}/Auth/Authenticate`;
        return this.http.post(url, null, options);
    }

    getToken(): Observable<any> {

        let url = `${this.baseURL}/Auth/AuthenticateUserless`;
       return this.http.get(url);
    }
}

import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  // Angular service worker cache suffix
  private readonly _cacheNamePrefix = 'ngsw:';
  // Table names are defined in the ngsw-config.json
  private readonly _cacheNameSuffix = 'api-';

  /**
   * Cleans up all API cache in Cache Storage
   */
  clearAllCache(): Observable<void> {
    return from(this._clearCacheByTableName(this._cacheNamePrefix, this._cacheNameSuffix));
  }

  /**
   * Cleans up specific Cache Storage table
   * @param cacheNameSuffix Will clean up tables that starts with this table name suffix
   */
  clearCacheTables(cacheNameSuffix: string): Observable<void> {
    return from(this._clearCacheByTableName(this._cacheNamePrefix, cacheNameSuffix));
  }

  /**
   * Removes records that match baseUrl in all Cache Storage tables
   * @param baseUrl Will clean up tables that starts with this table name suffix
   */
  clearCacheByUrl(baseUrl: string): Observable<void> {
    return from(this._clearCacheRecordsByUrl(this._cacheNamePrefix, baseUrl));
  }

  /**
   * Cleans up a whole Cache Storage table
   * @param cacheNamePrefix Table name prefix
   * @param cacheNameSuffix Table name suffix
   */
  private async _clearCacheByTableName(cacheNamePrefix: string, cacheNameSuffix: string) {
    // Get cache names list that matches the prefix
    const cacheList = await this.getCacheList(cacheNamePrefix, cacheNameSuffix);

    // Iterate over each cache and try finding the url there
    for (const cacheName of cacheList) {
      const cache = await caches.open(cacheName);
      const keys = await cache.keys();
      // Clean up all records
      await keys.map(keySearched => cache.delete(keySearched.url)
        .then((res) => this._logDelete(res, keySearched)));
    }
  }

  /**
   * Deletes records that matches the url partially
   * @param cacheNamePrefix Table name prefix
   * @param url Part of API url
   */
  private async _clearCacheRecordsByUrl(cacheNamePrefix: string, url: string) {
    // Get cache names list that matches the prefix
    const cacheList = await this.getCacheList(cacheNamePrefix);

    // Iterate over each cache and try finding the url there
    for (const cacheName of cacheList) {
      const cache = await caches.open(cacheName);
      const keys = await cache.keys();
      const filteredRecords = keys.filter(item => {
        return item.url.includes(url);
      });
      // Clean up records that matches url
      await filteredRecords.map(keySearched => cache.delete(keySearched.url)
        .then((res) => this._logDelete(res, keySearched)));
    }
  }

  /**
   * Returns list of available Cache Storage table names
   * @param cacheNamePrefix Table name prefix
   * @param cacheNameSuffix Table name suffix
   */
  private async getCacheList(cacheNamePrefix: string, cacheNameSuffix: string = ''): Promise<string[]> {
    const cacheNames = await caches.keys();
    return cacheNames.filter(cacheName => {
      if (new RegExp(`^${cacheNamePrefix}.*?${cacheNameSuffix}.*`).test(cacheName)) {
        return true;
      }
    });
  }

  /**
   * Logs the cache delete operation status
   * @param status Status of an operation
   * @param cacheName Name of cache record
   */
  private _logDelete(status: boolean, cacheName: any): void {
    // tslint:disable-next-line:no-console
    console.info(`Cache delete for ${cacheName}:`, (status ? 'Success' : 'Fail'));
  }
}

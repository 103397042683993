import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {AppService} from './app.service';

@Injectable({
    providedIn: 'root'
})
export class CompanysettingService {

  private baseURL = this.appService.config.apiBaseUrl;

    private options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };


  constructor(private http: HttpClient,
              private appService: AppService) { }

    GetCompanySettings(): Observable<any> {
        return this.http.get<any>(
            `${this.baseURL}/CompanySettings/GetCompanySettings`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const companysettingDetails = res.body as any;
                    return companysettingDetails;
                }),
                catchError(this.handleError)
            );

    }

    GetFacilityDayEndSettings(facId): Observable<any> {
        return this.http.get<any>(
            `${this.baseURL}/DayEnd/GetFacilityDayEndSetting?facilityId=${facId}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    return res.body as any;
                }),
                catchError(this.handleError)
            );
    }

    getLateAndLienSettings(facId): Observable<any> {
        return this.http.get<any>(
            `${this.baseURL}/Prospects/GetFacilityLateandLienSettings?facilityId=${facId}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const lateAndLien = res.body as any;
                    return lateAndLien;
                }),
                catchError(this.handleError)
            );
    }

    saveCompanyDetails(companyDetails): Observable<any> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const url = `${this.baseURL}/CompanySettings/SaveCompanySettings`;
        return this.http.post<any>(url, companyDetails, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof Error) {
            const errMessage = error.error.message;
            return throwError(errMessage);
        }
        return throwError(error || 'Node.js server error');
    }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { promise } from 'protractor';
import { ITdBankResponse } from '../Shared/models/Payment';
import { PaymentService } from '../services/payment.service';
import { UserSessionService } from '../services/usersession.service';

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.scss']
})
export class MasterComponent implements OnInit {

  tdResponseObj: ITdBankResponse;

constructor(private route: ActivatedRoute,
  private paymentService: PaymentService,
  private userSessionService: UserSessionService) {}

  ngOnInit() {
    console.log('abs Route ', window.location.href);
    console.log('User Session Service', this.userSessionService.userDetails);
    let currentUrl = window.location.href;
    // const myParam = urlParams.get('myParam');
     
   
    
  }
 
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { AppInsightsService } from '../app-insights.service';
import { Observable, pipe, throwError } from 'rxjs';
import { IProspectContactInfo, IProspectMarketing, IProspectsFilter, IProspectsAllList, ICallerScript, IProspectsModel, INearestLocation, IProspectUnitTypeModel, IProspectUnitList, IProspectUnitSearchList, IFollowUpInfo, INotesInfo, IProspectSave, IHistoryDeails, IUnitInfo, IMonthlyChargesInfo, IFollowUpDripCampaign, ICostToReserveRentModel, IFacilityReservationSettings, IFeesChargesInfo, IResponseModel, ITimeZoneModel, IKeypadZoneModel, IDemographyDetails, IFacPaymentSettingsDetails, GetLettersByTemplatesModel, IProspectsMatchingContactList, ITaxInfoModel } from 'src/app/Shared/models/ProspectModel';
import { map, catchError } from 'rxjs/operators';
import { ISaveDeleteResponse, IGetLettersAndNotices, ILettersForInfoSheet, ISaveOpenStoreResponse, ISaveCloseStoreResponse, IGETStoreDetails, IGetOpenStore } from 'src/app/Shared/interface';
import { IMerchandiseOperationsListModel } from 'src/app/Shared/models/MerchandiseModel';
import { EPaymentOrigin } from 'src/app/Shared/enum';
import { FingerPrintService } from '../fingerPrint/fingerprint.service';
import { AppService } from '../app.service';
import { UserSessionService } from '../usersession.service';
import { IFacilityAllowanceItemsModel, IUnitAllowanceItemsCount } from '../../Shared/models/CustomerModel';
import { RingCentralCTService } from './ringcentral.service';


@Injectable({
    providedIn: 'root',
})

export class ProspectService {
    private baseURL = this.appService.config.apiBaseUrl;
    public paymentSettings;
    public payFacId;
    constructor(private http: HttpClient,
        private appinsights: AppInsightsService,
        private userSessionService: UserSessionService,
        private appService: AppService,
        public fingerPrintService: FingerPrintService,
        private ringCentralCTService: RingCentralCTService) { }

    getFacilityAuction(facilityId): Observable<any> {
        return this.http.get<any>(
            `${this.baseURL}/prospects/GetFacilityAuction?facilityId=${facilityId}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const auctionInfo = res.body as any;
                    return auctionInfo;
                }),
                catchError(this.handleError)
            );
    }

    getContactInfo(tenantId: number): Observable<IProspectContactInfo> {
        return this.http.get<IProspectContactInfo>(
            `${this.baseURL}/prospects/GetTenantDetailsById?tenantId=${tenantId}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const contactInfo = res.body as IProspectContactInfo;
                    return contactInfo;
                }),
                catchError(this.handleError)
            );
    }

    GetFacilityDayEndSettings(facId): Observable<any> {
        return this.http.get<any>(
            `${this.baseURL}/DayEnd/GetFacilityDayEndSetting?facilityId=${facId}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    return res.body as any;
                }),
                catchError(this.handleError)
            );
    }

    GetMarketingInfo(facilityId: number): Observable<IProspectMarketing> {
        return this.http.get<IProspectMarketing>(
            `${this.baseURL}/prospects/GetMarketingInfo?facilityId=${facilityId}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const MarketingInfo = res.body as IProspectMarketing;
                    return MarketingInfo;
                }),
                catchError(this.handleError)
            );
    }

    GetMarketingInfoForPhoneNumber(phoneNumber: number): Observable<IProspectMarketing> {
        return this.http.get<IProspectMarketing>(
            `${this.baseURL}/prospects/GetMarketingInfoForPhoneNumber?phoneNumber=${phoneNumber}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const MarketingInfoForPhoneNumber = res.body as IProspectMarketing;
                    return MarketingInfoForPhoneNumber;
                }),
                catchError(this.handleError)
            );
    }

    getProspectsList(prospectFilters: IProspectsFilter): Observable<IProspectsAllList> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const url = `${this.baseURL}/Prospects/GetProspectsList`;
        return this.http.post<IProspectsAllList>(url, prospectFilters, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    getpaymentSettings(facId): Observable<IFacPaymentSettingsDetails> {
        this.payFacId = facId;
        return this.http.get<IFacPaymentSettingsDetails>(
            `${this.baseURL}/Prospects/GetFacilityPaymentSettings?facilityId=${facId}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const paySettings = res.body as IFacPaymentSettingsDetails;
                    this.paymentSettings = paySettings;
                    return paySettings;
                }),
                catchError(this.handleError)
            );
    }

    CreateTenantInfo(contactInfo): Observable<ISaveDeleteResponse> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        let url = '';
        if (contactInfo.TenantId === 0) {
            url = `${this.baseURL}/Prospects/CreateTenantInfo`;
        } else {
            url = `${this.baseURL}/Prospects/UpdateTenantInfo`;
        }

        return this.http.post<ISaveDeleteResponse>(url, contactInfo, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    UpdateTenantInfo(contactInfo): Observable<ISaveDeleteResponse> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const url = `${this.baseURL}/Prospects/UpdateTenantInfo`;
        return this.http.post<ISaveDeleteResponse>(url, contactInfo, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    saveCompleteProspect(prospects): Observable<any> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        let url = '';
        if (prospects.InquiryInfo[0].InquiryNumber === 0) {
            url = `${this.baseURL}/Prospects/CreateTenantInquiryInfo`;
        } else {
            url = `${this.baseURL}/Prospects/UpdateTenantInquiryInfo`;
        }

        return this.http.post<any>(url, prospects, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    saveReservation(reservation): Observable<ISaveDeleteResponse> {
        let options = {};
        if (this.fingerPrintService.paymentOrigin == EPaymentOrigin.Reservation && this.fingerPrintService.isFingerPrintUsed) {
            options = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'secondary_userId': `${this.fingerPrintService.userId}` }) };
        } else {
            options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        }
        let url = '';
        if (reservation.ReservationInfo[0].ReservationNumber === 0) {
            url = `${this.baseURL}/Prospects/CreateTenantReservationInfo`;
        } else {
            url = `${this.baseURL}/Prospects/UpdateTenantReservationInfo`;
        }

        return this.http.post<ISaveDeleteResponse>(url, reservation, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    saveRental(reservation: any): Observable<any[]> {
        let options = {};
        if (this.fingerPrintService.paymentOrigin == EPaymentOrigin.Rental && this.fingerPrintService.isFingerPrintUsed) {
            options = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'secondary_userId': `${this.fingerPrintService.userId}` }) };
        } else {
            options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        }
        let url = `${this.baseURL}/Prospects/CreateRentalInfo`;


        return this.http.post<any[]>(url, reservation, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    CreateB2CAccountforPortalUsers(portalInfo): Observable<any> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const url = `${this.baseURL}/Prospects/CreateB2CAccountforPortalUsers`;
        return this.http.post<any>(url, portalInfo, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    UpdateTenantInquiryInfo(inquiryInfo: IProspectsModel): Observable<boolean> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const url = `${this.baseURL}/Prospects/UpdateTenantInquiryInfo`;
        return this.http.post<ISaveDeleteResponse>(url, inquiryInfo, options)
            .pipe(
                map(res => {
                    return res.Success;
                }),
                catchError(this.handleError)
            );
    }

    CreateTenantReservationInfo(reservationInfo: IProspectsModel): Observable<boolean> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const url = `${this.baseURL}/Prospects/CreateTenantReservationInfo`;
        return this.http.post<ISaveDeleteResponse>(url, reservationInfo, options)
            .pipe(
                map(res => {
                    return res.Success;
                }),
                catchError(this.handleError)
            );
    }

    UpdateTenantReservationInfo(reservationInfo: IProspectsModel): Observable<boolean> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const url = `${this.baseURL}/Prospects/UpdateTenantReservationInfo`;
        return this.http.post<ISaveDeleteResponse>(url, reservationInfo, options)
            .pipe(
                map(res => {
                    return res.Success;
                }),
                catchError(this.handleError)
            );
    }

    CreateInquiryInfo(inquiryInfo: IProspectsModel): Observable<boolean> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const url = `${this.baseURL}/Prospects/CreateInquiryInfo`;
        return this.http.post<ISaveDeleteResponse>(url, inquiryInfo, options)
            .pipe(
                map(res => {
                    return res.Success;
                }),
                catchError(this.handleError)
            );
    }

    UpdateInquiryInfo(inquiryInfo: IProspectsModel): Observable<boolean> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const url = `${this.baseURL}/Prospects/UpdateInquiryInfo`;
        return this.http.post<ISaveDeleteResponse>(url, inquiryInfo, options)
            .pipe(
                map(res => {
                    return res.Success;
                }),
                catchError(this.handleError)
            );
    }

    CreateReservationInfo(reservationInfo: IProspectsModel): Observable<boolean> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const url = `${this.baseURL}/Prospects/CreateReservationInfo`;
        return this.http.post<ISaveDeleteResponse>(url, reservationInfo, options)
            .pipe(
                map(res => {
                    return res.Success;
                }),
                catchError(this.handleError)
            );
    }

    UpdateReservationInfo(reservationInfo: IProspectsModel): Observable<boolean> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const url = `${this.baseURL}/Prospects/UpdateReservationInfo`;
        return this.http.post<ISaveDeleteResponse>(url, reservationInfo, options)
            .pipe(
                map(res => {
                    return res.Success;
                }),
                catchError(this.handleError)
            );
    }

    getNearestLocation(zip, distance, CountryId): Observable<INearestLocation[]> {
        const url = CountryId ? `${this.baseURL}/Prospects/GetNearestLocations?ZipCode=${zip}&Distance=${distance}&CountryId=${CountryId}` :
            `${this.baseURL}/Prospects/GetNearestLocations?ZipCode=${zip}&Distance=${distance}`
        return this.http.get<INearestLocation[]>(
            url,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const nearestLocationList = res.body as INearestLocation[];
                    return nearestLocationList;
                }),
                catchError(this.handleError)
            );
    }

    getUnitTypeList(ProspectUnitTypeFilters): Observable<IProspectUnitTypeModel> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const url = `${this.baseURL}/Prospects/GetProspectsUnitTypeList`;
        return this.http.post<IProspectUnitTypeModel>(url, ProspectUnitTypeFilters, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }
    getUnitList(ProspectUnitFilters): Observable<IProspectUnitList[]> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const url = `${this.baseURL}/Prospects/GetProspectsUnitList`;
        return this.http.post<IProspectUnitList[]>(url, ProspectUnitFilters, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    getUnitDetails(ProspectUnitDetails): Observable<IUnitInfo[]> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const url = `${this.baseURL}/Prospects/GetProspectUnitDetails`;
        return this.http.post<IUnitInfo[]>(url, ProspectUnitDetails, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }
    getUnitSearchDetails(searchModel): Observable<IProspectUnitSearchList[]> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const url = `${this.baseURL}/Prospects/GetUnitNumberSearchList`;
        return this.http.post<IProspectUnitSearchList[]>(url, searchModel, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    getCostToRent(rentModel): Observable<ICostToReserveRentModel> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const url = `${this.baseURL}/Prospects/GetCostToRent`;
        return this.http.post<ICostToReserveRentModel>(url, rentModel, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    getCostToRentForTransfer(rentModel): Observable<ICostToReserveRentModel> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const url = `${this.baseURL}/Prospects/GetCostToRentForTransfer`;
        return this.http.post<ICostToReserveRentModel>(url, rentModel, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    GetPeriodWiseChargesForRentals(rentModel): Observable<ICostToReserveRentModel> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const url = `${this.baseURL}/Prospects/GetPeriodWiseChargesForRentals`;
        return this.http.post<ICostToReserveRentModel>(url, rentModel, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    getCostToReserve(reserveModel): Observable<ICostToReserveRentModel> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const url = `${this.baseURL}/Prospects/GetCostToReserve`;
        return this.http.post<ICostToReserveRentModel>(url, reserveModel, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    getAllFollowUps(tenantId: number): Observable<IFollowUpInfo[]> {
        return this.http.get<IFollowUpInfo[]>(
            `${this.baseURL}/Prospects/GetFollowUps?tenantId=${tenantId}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const followUps = res.body as IFollowUpInfo[];
                    return followUps;
                }),
                catchError(this.handleError)
            );
    }

    getAllNotes(tenantId: number): Observable<INotesInfo[]> {
        return this.http.get<INotesInfo[]>(
            `${this.baseURL}/Prospects/GetAllNotes?tenantid=${tenantId}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const notes = res.body as INotesInfo[];
                    return notes;
                }),
                catchError(this.handleError)
            );
    }

    getInqNotes(InqId: number, facId): Observable<INotesInfo[]> {
        return this.http.get<INotesInfo[]>(
            `${this.baseURL}/Prospects/GetInquiryNotes?inquiryNumber=${InqId}&inquiryfacilityId=${facId}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const notes = res.body as INotesInfo[];
                    return notes;
                }),
                catchError(this.handleError)
            );
    }

    getResrvNotes(ResId: number, facId): Observable<INotesInfo[]> {
        return this.http.get<INotesInfo[]>(
            `${this.baseURL}/Prospects/GetReservationNotes?reservationNumber=${ResId}&reservationfacilityId=${facId}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const notes = res.body as INotesInfo[];
                    return notes;
                }),
                catchError(this.handleError)
            );
    }

    getRentNotes(LeaseId: number, facId): Observable<INotesInfo[]> {
        return this.http.get<INotesInfo[]>(
            `${this.baseURL}/Prospects/GetRentalNotes?leaseNo=${LeaseId}&leasefacilityId=${facId}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const notes = res.body as INotesInfo[];
                    return notes;
                }),
                catchError(this.handleError)
            );
    }

    getHistory(tenantId: number): Observable<IHistoryDeails[]> {
        return this.http.get<IHistoryDeails[]>(
            `${this.baseURL}/Prospects/GetHistoryDetails?tenantId=${tenantId}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const history = res.body as IHistoryDeails[];
                    return history;
                }),
                catchError(this.handleError)
            );
    }
    getAllProspects(tenantId: number, isStr): Observable<IProspectSave[]> {
        return this.http.get<IProspectSave[]>(
            `${this.baseURL}/Prospects/GetAllProspectDetails?tenantId=${tenantId}&CallCenter=${isStr}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const prospectDetails = res.body as IProspectSave[];
                    return prospectDetails;
                }),
                catchError(this.handleError)
            );
    }
    getExistingContacts(fName: string, lName: string): Observable<IProspectContactInfo[]> {
        return this.http.get<IProspectContactInfo[]>(
            `${this.baseURL}/Prospects/GetTenantDetailsByName?firstName=${fName}&lastName=${lName}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const contactList = res.body as IProspectContactInfo[];
                    return contactList;
                }),
                catchError(this.handleError)
            );
    }

    getExistingTenantsbyPhnNo(Phnno: number): Observable<IProspectContactInfo[]> {
        return this.http.get<IProspectContactInfo[]>(
            `${this.baseURL}/Prospects/GetTenantDetailsByPhoneNumber?contactNumber=${Phnno}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const contactList = res.body as IProspectContactInfo[];
                    return contactList;
                }),
                catchError(this.handleError)
            );
    }

    getMonthlyCharges(MonthlyChargeInputModel): Observable<IMonthlyChargesInfo> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const url = `${this.baseURL}/Prospects/GetMonthlyCharges`;
        return this.http.post<IMonthlyChargesInfo>(url, MonthlyChargeInputModel, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    GetDripCampaignForFollowUp(facilityId: number, requiredDate: Date, appliedTo: number): Observable<IFollowUpDripCampaign> {
        return this.http.get<IFollowUpDripCampaign>(
            `${this.baseURL}/Prospects/GetDripCampaignForFollowUp?facilityId=${facilityId}&requiredDate=${requiredDate}&appliedTo=${appliedTo}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const dripCampaignData = res.body as IFollowUpDripCampaign;
                    return dripCampaignData;
                }),
                catchError(this.handleError)
            );
    }

    getFacilityMiscellaneousSettings(facId): Observable<any> {
        return this.http.get<any[]>(
            `${this.baseURL}/Prospects/GetFacilityMiscellaneousSettings?facilityId=${facId}`,
            { observe: 'response' })
            .pipe(
                map(res => {

                    return res.body as any;
                }),
                catchError(this.handleError)
            );
    }

    getDiscountValue(discountInput): Observable<any> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const url = `${this.baseURL}/Prospects/GetDiscountValue`;
        return this.http.post<any>(url, discountInput, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    //////////////////////Reservation /////////////////////////////////////
    getFacilityReservationSettings(facId): Observable<IFacilityReservationSettings> {
        return this.http.get<IFacilityReservationSettings>(
            `${this.baseURL}/Prospects/GetFacilityReservationSettings?facilityId=${facId}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    return res.body as IFacilityReservationSettings;
                }),
                catchError(this.handleError)
            );
    }

    getUnitLevelManualFees(unitId, facilityId): Observable<IFeesChargesInfo[]> {
        return this.http.get<IFeesChargesInfo[]>(
            `${this.baseURL}/Prospects/GetUnitLevelManualFeesForReservation?unitId=${unitId}&facilityId=${facilityId}`,
            { observe: 'response' }).pipe(
                map(res => {
                    return res.body as IFeesChargesInfo[];
                }),
                catchError(this.handleError)
            );

    }
    getProspectMerchandise(facilityId): Observable<IMerchandiseOperationsListModel[]> {
        return this.http.get<IMerchandiseOperationsListModel[]>(
            `${this.baseURL}/MerchandiseOperations/GetMerchandiseListForProspects?facilityId=${facilityId}`,
            { observe: 'response' }).pipe(
                map(res => {
                    return res.body as IMerchandiseOperationsListModel[];
                }),
                catchError(this.handleError)
            );

    }

    //////////////////////Rental /////////////////////////////////////
    getManualFeesForRental(unitId, facilityId): Observable<IFeesChargesInfo[]> {
        return this.http.get<IFeesChargesInfo[]>(
            `${this.baseURL}/Prospects/GetUnitLevelManualFeesAndChargesForRental?unitId=${unitId}&facilityId=${facilityId}`,
            { observe: 'response' }).pipe(
                map(res => {
                    return res.body as IFeesChargesInfo[];
                }),
                catchError(this.handleError)
            );

    }

    getManualFeesForTransfer(unitId, facilityId): Observable<IFeesChargesInfo[]> {
        return this.http.get<IFeesChargesInfo[]>(
            `${this.baseURL}/Prospects/GetUnitLevelManualFeesAndChargesForTransfer?unitId=${unitId}&facilityId=${facilityId}`,
            { observe: 'response' }).pipe(
                map(res => {
                    return res.body as IFeesChargesInfo[];
                }),
                catchError(this.handleError)
            );
    }

    GetLettersAndNotices(templateId) {
        return this.http.get<IGetLettersAndNotices>(
            `${this.baseURL}/General/GetLettersAndNotices?templateId=${templateId}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const data = res.body as IGetLettersAndNotices;
                    return data;
                }),
                catchError(this.handleError)
            );
    }

    GetLettersForInfoSheet(templateId) {
        return this.http.get<ILettersForInfoSheet>(
            `${this.baseURL}/General/GetLettersAndNotices?templateId=${templateId}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const data = res.body as ILettersForInfoSheet;
                    return data;
                }),
                catchError(this.handleError)
            );
    }

    AccessCodeExist(accessCode: string, tenantID, FacilityId, leaseNo, isAlternateContact, alternateContactId): Observable<boolean> {
        return this.http.get<boolean>(
            `${this.baseURL}/Prospects/AccessCodeExists?accessCode=${accessCode}&tenantID=${tenantID}&facilityId=${FacilityId}&leaseNo=${leaseNo}&isAlternateContact=${isAlternateContact}&alternateContactId=${alternateContactId}`,
            { observe: 'response' })
            .pipe(
                map(result => {
                    const gateCodeExist = result.body as boolean;
                    return gateCodeExist;
                }
                ),
                catchError(this.handleError)
            );
    }

    CheckFacilityGateSettingExist(facilityId: number): Observable<IResponseModel> {
        return this.http.get<IResponseModel>(
            `${this.baseURL}/Store/CheckFacilityGateSettingExist?facilityId=${facilityId}`,
            { observe: 'response' })
            .pipe(
                map(result => {
                    const gateSystemExists = result.body as IResponseModel;
                    return gateSystemExists;
                }
                ),
                catchError(this.handleError)
            );
    }

    GetFacilityTimeZone(facilityId, gateId): Observable<ITimeZoneModel[]> {
        return this.http.get<ITimeZoneModel[]>(
            `${this.baseURL}/Store/GetFacilityTimeZone?facilityId=${facilityId}&gateId=${gateId}`,
            { observe: 'response' }).pipe(
                map(res => {
                    return res.body as ITimeZoneModel[];
                }),
                catchError(this.handleError)
            );

    }

    GetFacilityKeyPadZone(facilityId, gateId): Observable<IKeypadZoneModel[]> {
        return this.http.get<IKeypadZoneModel[]>(
            `${this.baseURL}/Store/GetFacilityKeyPadZone?facilityId=${facilityId}&gateId=${gateId}`,
            { observe: 'response' }).pipe(
                map(res => {
                    return res.body as IKeypadZoneModel[];
                }),
                catchError(this.handleError)
            );

    }

    GetDempographicDetails(facilityId): Observable<IDemographyDetails[]> {
        return this.http.get<IDemographyDetails[]>(
            `${this.baseURL}/Prospects/GetFacilityMarketingDetails?facilityId=${facilityId}`,
            { observe: 'response' }).pipe(
                map(res => {
                    return res.body as IDemographyDetails[];
                }),
                catchError(this.handleError)
            );
    }

    getLetterId(unitId): Observable<any> {
        return this.http.get<any>(
            `${this.baseURL}/Prospects/GetLetterandNoticesIdforaUnit?unitId=${unitId}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const lettersList = res.body as any;
                    return lettersList;
                }),
                catchError(this.handleError)
            );
    }

    private handleError(error: HttpErrorResponse) {
        console.error('server error:', error);
        if (error.error instanceof Error) {
            const errMessage = error.error.message;
            this.appinsights.logEvent(errMessage);
            return throwError(errMessage);
        }
        return throwError(error || 'Node.js server error');
    }

    SaveOpenStoreDetails(SaveOpenStoreList: any): Observable<ISaveOpenStoreResponse> {

        let options = {};
        if (this.fingerPrintService.isFingerPrintUsed) {
            options = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'secondary_userId': `${this.fingerPrintService.userId}` }) };
        } else {
            options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        }
        let url = '';
        url = `${this.baseURL}/OpenCloseStore/OpenCloseStore`;

        return this.http.post<ISaveOpenStoreResponse>(url, SaveOpenStoreList, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    SaveEditdetails(SaveOpenStoreList: any): Observable<ISaveOpenStoreResponse> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        let url = '';
        url = `${this.baseURL}/OpenCloseStore/SaveCashRegisterFacilityDayBegin`;

        return this.http.post<ISaveOpenStoreResponse>(url, SaveOpenStoreList, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    SaveCloseStoreDetails(SaveCloseStoreList: any): Observable<ISaveCloseStoreResponse> {
        let options = {};
        if (this.fingerPrintService.isFingerPrintUsed) {
            options = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'secondary_userId': `${this.fingerPrintService.userId}` }) };
        } else {
            options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        }
        let url = '';
        url = `${this.baseURL}/OpenCloseStore/CloseStore`;

        return this.http.post<ISaveCloseStoreResponse>(url, SaveCloseStoreList, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    GetStoreDetailsList(facilityId: number): Observable<IGETStoreDetails> {
        return this.http.get<IGETStoreDetails>(
            `${this.baseURL}/OpenCloseStore/GetStoreCollection?facilityid=${facilityId}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const contactInfo = res.body as IGETStoreDetails;
                    return contactInfo;
                }),
                catchError(this.handleError)
            );
    }

    GetOpenStore(facilityId: number): Observable<IGetOpenStore> {
        return this.http.get<IGetOpenStore>(
            `${this.baseURL}/OpenCloseStore/GetOpenStore?facilityid=${facilityId}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const contactInfo = res.body as IGetOpenStore;
                    return contactInfo;
                }),
                catchError(this.handleError)
            );
    }

    SaveNotes(notesList: any): Observable<ISaveCloseStoreResponse> {
        let options = {};
        if (this.fingerPrintService.isFingerPrintUsed) {
            options = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'secondary_userId': `${this.fingerPrintService.userId}` }) };
        } else {
            options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        }
        let url = '';
        url = `${this.baseURL}/Prospects/CreateNotes`;

        return this.http.post<ISaveCloseStoreResponse>(url, notesList, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    getFacilityAllowanceItems(facilityID): Observable<IFacilityAllowanceItemsModel[]> {
        return this.http.get<IFacilityAllowanceItemsModel[]>(
            `${this.baseURL}/Prospects/GetFacilityAllowanceItems?facilityId=${facilityID}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const allowanceItems = res.body as IFacilityAllowanceItemsModel[];
                    return allowanceItems;
                }),
                catchError(this.handleError)
            );
    }

    GetAllowanceCount(leaseNo: number, reservationNo: number, facilityID): Observable<IUnitAllowanceItemsCount[]> {
        return this.http.get<IUnitAllowanceItemsCount[]>(
            // actual applicable for lease
            `${this.baseURL}/Prospects/GetAllowanceCount?leaseNo=${leaseNo}&reservationNo=${reservationNo}&facilityId=${facilityID}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const allowanceCount = res.body as IUnitAllowanceItemsCount[];
                    return allowanceCount;
                }),
                catchError(this.handleError)
            );
    }

    getLettersByTransactionType(facId, transTypeId): Observable<any> {
        return this.http.get<any>(
            `${this.baseURL}/DocumentMerger/GetLettersByTransactionType?facilityId=${facId}&transactionTypeId=${transTypeId}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const lettersList = res.body as any;
                    return lettersList;
                }),
                catchError(this.handleError)
            );
    }

    GetInquiryFollowUps(inquiryNumber, inquiryFacilityId): Observable<IFollowUpInfo[]> {
        return this.http.get<IFollowUpInfo[]>(
            `${this.baseURL}/Prospects/GetInquiryFollowUps?inquiryNumber=${inquiryNumber}&inquiryFacilityId=${inquiryFacilityId}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const inquiryFollowUps = res.body as IFollowUpInfo[];
                    return inquiryFollowUps;
                }),
                catchError(this.handleError)
            );
    }

    GetReservationFollowUps(reservationNumber, reservationFacilityId): Observable<IFollowUpInfo[]> {
        return this.http.get<IFollowUpInfo[]>(
            `${this.baseURL}/Prospects/GetReservationFollowUps?reservationNumber=${reservationNumber}&reservationFacilityId=${reservationFacilityId}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const reservationFollowUps = res.body as IFollowUpInfo[];
                    return reservationFollowUps;
                }),
                catchError(this.handleError)
            );
    }

    getLettersByTemplates(TemplateTypeLettersModel): Observable<GetLettersByTemplatesModel> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        let url = '';
        url = `${this.baseURL}/DocumentMerger/GetLettersByTemplates`;

        return this.http.post<GetLettersByTemplatesModel>(url, TemplateTypeLettersModel, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    getContactInfoByEmailAddress(emailId): Observable<any> {
        return this.http.get<any>(
            `${this.baseURL}/prospects/GetTenantDetailsByEmailID?emailID=${emailId}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const contactInfo = res.body as any;
                    return contactInfo;
                }),
                catchError(this.handleError)
            );
    }

    getprospectsfilters(): Observable<any> {
        return this.http.get<any>(
            `${this.baseURL}/prospects/GeFilterforProspectListing`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const filterInfo = res.body as any;
                    return filterInfo;
                }),
                catchError(this.handleError)
            );
    }

    CheckTenantEmailB2CAccountExists(emailIdModel): Observable<any> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        let url = `${this.baseURL}/prospects/CheckTenantEmailB2CAccountExists`;
        return this.http.post<any>(url, emailIdModel, options)
            .pipe(
                map(res => {
                    const isExists = res as any;
                    return isExists;
                }),
                catchError(this.handleError)
            );
    }

    getLettersByTemplatesForpaymentReceipt(TemplateTypeLettersModel): Observable<GetLettersByTemplatesModel[]> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        let url = '';
        url = `${this.baseURL}/DocumentMerger/GetLettersByTemplates`;

        return this.http.post<GetLettersByTemplatesModel[]>(url, TemplateTypeLettersModel, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    getFacilityProspectSettings(facId): Observable<any> {
        return this.http.get<any[]>(
            `${this.baseURL}/Prospects/GetFacilityProspectSetting?facilityId=${facId}`,
            { observe: 'response' })
            .pipe(
                map(res => {

                    return res.body as any;
                }),
                catchError(this.handleError)
            );
    }

    getLetterIdByUnitId(facilityId, unitId): Observable<any> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), };
        return this.http.get<any>(
            `${this.baseURL}/DocumentMerger/GetLetterIdByUnitId?facilityId=${facilityId}&unitId=${unitId}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const contactInfo = res.body as any;
                    return contactInfo;
                }),
                catchError(this.handleError)
            );
    }

    GetInquiryDetailsByNum(InquiryNum: number, facilityId, isCallcenter): Observable<any> {
        return this.http.get<any>(
            `${this.baseURL}/Prospects/GetInquiryDetailsByInquiryNumber?inquiryNumber=${InquiryNum}&facilityId=${facilityId}&CallCenter=${isCallcenter}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const contactInfo = res.body as any;
                    return contactInfo;
                }),
                catchError(this.handleError)
            );
    }

    GetPortalLoginDetails(tenantId): Observable<any> {
        return this.http.get<any>(
            `${this.baseURL}/Prospects/GetPortalLoginDetails?tenantId=${tenantId}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const portalList = res.body;
                    return portalList;
                }),
                catchError(this.handleError)
            );
    }

    saveCompleteProspectForcedInquiry(prospects): Observable<any> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        let url = `${this.baseURL}/Prospects/CreateTenantInquiryInfo`;
        return this.http.post<any>(url, prospects, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    GetMatchingRecordsofTenant(FName: string, lName: string, EmailId: string, Phno: string): Observable<IProspectsMatchingContactList> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const url = `${this.baseURL}/Prospects/GetMatchingRecordsofTenant?FName=${FName}&LName=${lName}&EmailId=${EmailId}&Phno=${Phno}`;
        return this.http.post<IProspectsMatchingContactList>(url, null, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    GetOldestVacantUnit(unitTypeId, facilityId, category): Observable<any> {
        return this.http.get<any[]>(
            `${this.baseURL}/Prospects/GetOldestVacantUnit?unitTypeId=${unitTypeId}&facilityId=${facilityId}&category=${category}`,

            { observe: 'response' })
            .pipe(
                map(res => {
                    const unitId = res.body;
                    return unitId;
                }),
                catchError(this.handleError)
            );
    }

    GetFacilityCancellation(facilityId): Observable<any> {
        return this.http.get<any[]>(
            `${this.baseURL}/Prospects/GetFacilitycancelation?facilityId=${facilityId}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const cancellationreasons = res.body;
                    return cancellationreasons;
                }),
                catchError(this.handleError)
            );
    }

    getMatchingContacts(inputModel): Observable<IProspectContactInfo[]> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const url = `${this.baseURL}/Prospects/GetTenantDetailsForInput`;
        return this.http.post<IProspectContactInfo[]>(url, inputModel, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    callTrackerDetails(res, facilityId, type) {
        let userID = 1;
        if (this.userSessionService.userSessionData.UserId && this.userSessionService.userSessionData.UserId != 0) {
            userID = this.userSessionService.userSessionData.UserId;
        }
        let item = localStorage.getItem('calltracker');
        let ctData;
        if (item) {
            ctData = JSON.parse(item);
        }
        if (ctData) {
            console.log(ctData);
            if (ctData.CallRecordingId) {
                const objCallTracker = {
                    PhoneId: ctData.PhoneId,
                    OutboundCall: ctData.OutboundCall,
                    PhoneSystem: ctData.PhoneSystem,
                    CallDuration: 0,
                    CallSID: ctData.CallRecordingId,
                    CallRecordingId: ctData.CallRecordingId,
                    TrackingNumber: ctData.TrackingNumber,
                    PhoneNumber: ctData.PhoneNumber,
                    IncompleteCallUserId: userID,
                    IncompleteCallConverted: false,
                    IncompleteCallConvertedDatetime: new Date(),
                    IncompleteCallConvertedUserId: userID,
                    FacilityId: facilityId,
                    TenantID: res.TenantId == 0 ? null : res.TenantId,
                    ExistingTenantCall: res.TenantId == 0 ? false : true,
                    AdSourceID: this.ringCentralCTService.marketingInfoPhone && this.ringCentralCTService.marketingInfoPhone.AdSourceListModel.length > 0 ? this.ringCentralCTService.marketingInfoPhone.AdSourceListModel[0].Id : 1,
                    IncompleteCall: false,
                    LeaseNumber: type == 'RENT' ? res.Id : null,
                    ReservationNumber: type == 'RESV' ? res.Id : null,
                    InquiryNumber: type == 'INQR' ? res.Id : null,
                    CallCenterIntegrationId: ctData.CallCenterIntegrationId ? ctData.CallCenterIntegrationId : undefined
                }
                this.userSessionService.FillCallTrackerDetails(objCallTracker);
            }
        }
    }

    getCurrentUnitSatus(unitId, facId): Observable<any> {
        return this.http.get<any>(
            `${this.baseURL}/Prospects/GetUnitStatustoConvertInquiryAndReservation?unitId=${unitId}&facilityId=${facId}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const response = res.body as any;
                    return response;
                }),
                catchError(this.handleError)
            );
    }

    getRentalStatus(rentalInfo, isReservation, isEnquiry): Observable<any> {
        let url = isReservation ? (`${this.baseURL}/Prospects/GetReservationStatus`) : isEnquiry ?(`${this.baseURL}/Prospects/GetInquiryStatus`) : `${this.baseURL}/Prospects/GetRentalStatus`;
        return this.http.post<any>(url, rentalInfo, { observe: 'response' })
            .pipe(map(res => {
                return res.body as any;
            }),
                catchError(this.handleError)
            );
    }

    GetTransferOrMoveoutStatus(transferInfo, isMovedout): Observable<any> {
        let url = isMovedout ? `${this.baseURL}/MoveOut/GetMoveOutStatus` : `${this.baseURL}/Prospects/GetTransferStatus`;
        return this.http.post<any>(url, transferInfo, { observe: 'response' })
            .map(pipe(res => {
                return res.body as any;
            }),
                catchError(this.handleError)
            );
    }

    getLedgerBalance(leaseNo, facilityId): Observable<any> {
        return this.http.get<any>(`${this.baseURL}/Prospects/GetLedgerBalance?leaseNo=${leaseNo}&facilityId=${facilityId}`,
            {observe:'response'})
            .pipe(
                map(res => {
                    return res.body as any;
                }),
                catchError(this.handleError)
            );
    }

    getTaxValueFromAPI(taxInputModel: ITaxInfoModel[], amount: number): Promise<number> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const url = `${this.baseURL}/Prospects/GetTaxValue?amount=${amount}`;
        return this.http.post<number>(url, taxInputModel, options).toPromise().then(r => {
            return r;
          });
    }

    
    getEachTaxValueFromAPI(taxInputModel: ITaxInfoModel[], amount: number): Promise<ITaxInfoModel[]> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const url = `${this.baseURL}/Prospects/GetEachTaxValue?amount=${amount}`;
        return this.http.post<ITaxInfoModel[]>(url, taxInputModel, options).toPromise().then(r => {
            return r;
          });
    }
}

import { Pipe, PipeTransform } from "@angular/core";
import { CurrencyPipe } from '@angular/common';


@Pipe({
  name: 'customCurrency'
})

export class customCurrencyPipe implements PipeTransform {

  constructor(private currencyPipe: CurrencyPipe) { }

  transform(value: string): string {
    if (parseFloat(value) < 0) {
      return `(${this.currencyPipe.transform(value.toString().replace('-',''))})`;
    }
    return this.currencyPipe.transform(value);
  }
}
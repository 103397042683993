import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AppInsightsService } from './services/app-insights.service';
import { environment } from 'src/environments/environment';
import { Subject, timer } from 'rxjs';
import { UserService } from './services/user.service';
import { UserSessionService } from './services/usersession.service';
import { IUser, ISaveDeleteResponse, IApiResponse } from './Shared/interface';
import { ILoggedInUser, IGeoLocationLoginModel } from './Shared/models/ProspectModel';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FingerPrintService } from './services/fingerPrint/fingerprint.service';
import { filter, takeUntil } from 'rxjs/operators';
import { AppService } from './services/app.service';
import { BaseComponent } from './Shared/base.component';
import { VersionService } from './services/version.service';
import { IdTokenClaims, PromptValue } from '@azure/msal-common';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import jwt_decode from 'jwt-decode';
import { CacheService } from './services/cache.service';

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string,
  tfp?: string,
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
  isApiCalled = false;
  AzureRespone;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  b2cPolicies = {
    names: {
      signUpSignIn: 'B2C_1_NextGen_SignIn',
      resetPassword: 'B2C_1_Password_Reset'
    },
    authorities: {
      signUpSignIn: {
        authority: environment.authority,
      },
      resetPassword: {
        authority: environment.authority.replace('B2C_1_NextGen_SignIn', 'B2C_1_Password_Reset')
      }
    },
    authorityDomain: environment.authority.split('/')[2],
  };

  loggedInUserDetails: ILoggedInUser;
  title = 'ClientApp';
  userId;
  UserDetail: IUser;
  processDayEnd;
  @ViewChild('warning', { static: true }) warning: ModalDirective;
  @ViewChild('geoLocationWarning', { static: true }) geoLocationWarning: ModalDirective;
  @ViewChild('storeNotFound', { static: true }) storeNotFound: ModalDirective;
  @ViewChild('noStoresFound', { static: true }) noStoresFound: ModalDirective;
  @ViewChild('appNewVersion', { static: true }) appNewVersion: ModalDirective;
  @ViewChild('authenticationError', { static: true }) authenticationError: ModalDirective;

  currentLat = 0;
  currentLong = 0;
  fingerPrintInit: any;

  constructor(private appInsightService: AppInsightsService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    public userService: UserService,
    private userSessionService: UserSessionService,
    public fingerPrintService: FingerPrintService,
    private appService: AppService,
    private versionService: VersionService,
    private cacheService: CacheService) {
    super();
  }

  ngOnInit(): void {
    this.appService.waitForStable$(timer(1)).subscribe(() => {
      console.log('Application is stable');
    });

    this.versionService.startVersionPolling(() => {
      this.appNewVersion.show();
    });

    let url = window.location.href.includes('/prospect/screenpopup?phone') ? true : false;
    if (!url) {
      this.getLoggedInUserDetails();
    } else {
      setTimeout(() => {
        this.getLoggedInUserDetails();
      }, 400);

    }
    this.appInsightService.logEvent('SSM-Application Loaded ');
    if (environment.isFingerPrintEnabled) {
      this.fingerPrintInit = new this.fingerPrintService.FingerprintSdkTest(this.fingerPrintService);

      const source = timer(8000);
      this.appService.waitForStable$(source).pipe(
        takeUntil(this.unsubscribeOnDestroy$)
      ).subscribe(val => this.fingerPrintInit.startCapture());
    }
    localStorage.setItem('rcTwilioPopup', '1');


    //////// MSAL 2.0 ////////////////////
    this.authService.instance.enableAccountStorageEvents();

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      })

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
          || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
          //  || msg.eventType === EventType.SSO_SILENT_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        let payload = result.payload as AuthenticationResult;
        let idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;
        const acr = idtoken.acr ? (idtoken.acr).toLowerCase() : idtoken.acr;
        const tfp = idtoken.tfp ? (idtoken.tfp).toLowerCase() : idtoken.tfp;

        if (acr === (this.b2cPolicies.names.signUpSignIn).toLocaleLowerCase() || tfp === (this.b2cPolicies.names.signUpSignIn).toLocaleLowerCase()) {
          this.authService.instance.setActiveAccount(payload.account);
          this.AzureRespone = jwt_decode(payload.idToken);
          console.log('AzureRespone', this.AzureRespone);
          if (!this.isApiCalled) {
            this.isApiCalled = true;
            this.updateLoginDate();
          }
        }

        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth response resulting
         * from SUSI flow. "acr" claim in the id token tells us the policy (NOTE: newer policies may use the "tfp" claim instead).
         * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */

        /**
         * Below we are checking if the user is returning from the reset password flow.
         * If so, we will ask the user to reauthenticate with their new password.
         */

        if (acr === (this.b2cPolicies.names.resetPassword).toLocaleLowerCase() || tfp === (this.b2cPolicies.names.resetPassword).toLocaleLowerCase()) {
          let signUpSignInFlowRequest: RedirectRequest | PopupRequest = {
            authority: this.b2cPolicies.authorities.signUpSignIn.authority,
            prompt: PromptValue.LOGIN, // force user to reauthenticate with their new password
            scopes: []
          };
          this.login(signUpSignInFlowRequest);
        }
        return result;
      });


    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        // Checking for the forgot password error. Learn more about B2C error codes at
        // https://learn.microsoft.com/azure/active-directory-b2c/error-codes
        if (result.error && result.error.message.indexOf('AADB2C90118') > -1) {
          let resetPasswordFlowRequest: RedirectRequest | PopupRequest = {
            authority: this.b2cPolicies.authorities.resetPassword.authority,
            scopes: [],
          };
          this.login(resetPasswordFlowRequest);
        } else {
          let signUpSignIn: RedirectRequest | PopupRequest = {
            authority: this.b2cPolicies.authorities.signUpSignIn.authority,
            scopes: [],
          };
          this.login(signUpSignIn);
        }
      });
  }

  // ngOnDestroy() {
  //   this.broadcastService.getMSALSubject().next(1);
  //   if (this.subscription) {
  //     this.subscription.unsubscribe();
  //   }
  // }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      // add your code for handling multiple accounts here
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    let loginHintData = ' ';
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest, loginHint: loginHintData } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService.loginPopup({ ...userFlowRequest, loginHint: loginHintData })
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest, loginHint: loginHintData } as RedirectRequest);
      } else {
        this.authService.loginRedirect({ ...userFlowRequest, loginHint: loginHintData });
      }
    }
  }

  updateLoginDate(): void {
    this.userService.updateLoginDate().subscribe((response: ISaveDeleteResponse) => {
      this.userId = response.Id;
    });
  }

  getUserDetail(id: number): void {
    this.userService.getUserDetail(id).subscribe((response: IUser) => { });
  }

  getLoggedInUserDetails(): void {
    this.userService.getLoggedInUserDetails().subscribe((response: ILoggedInUser) => {
      if (response == null || response == undefined || response.IsB2CObjectIdAuthenticationId == false) {
        this.authenticationError.config = { backdrop: 'static' };
        this.authenticationError.show();
      } else {
        this.clearCache();
        this.loggedInUserDetails = response;
        console.log('Logged in users Id', this.loggedInUserDetails);
        if (!this.loggedInUserDetails.UserGroupCallCenter) {
          this.allAssignedStoresList();
        }
        if (response.GeolocationBasedLogin) {
          if (this.loggedInUserDetails.UserDetails.StorageFacilities.length > 0 && this.loggedInUserDetails.UserDetails.StorageFacilities) {
            const result = this.getGeoLocationDetails();
          } else {
            this.noStoresFound.config = { backdrop: 'static' };
            this.noStoresFound.show();
          }
        } else if (response.IPBasedLogin) {
          let storeDetails;
          if (this.loggedInUserDetails.UserDetails.StorageFacilities.length > 0 && this.loggedInUserDetails.UserDetails.StorageFacilities) {
            this.loggedInUserDetails.UserDetails.StorageFacilities.forEach(store => {
              if (store.FacilityIP) {
                store.FacilityIP.forEach(sip => {
                  if (sip == this.loggedInUserDetails.UserDetails.UserIP) {
                    storeDetails = store;
                  }
                });
              }
            });
            if (storeDetails !== null && storeDetails !== undefined) {
              this.userSessionService.FillUserSessionData(this.loggedInUserDetails, null, storeDetails.Id);
            } else {
              this.storeNotFound.config = { backdrop: 'static' };
              this.storeNotFound.show();
            }
          } else {
            this.noStoresFound.config = { backdrop: 'static' };
            this.noStoresFound.show();
          }
        } else {
          if (this.loggedInUserDetails.UserGroupCallCenter) {
            this.loggedInUserDetails.UserDetails.StoreGroupUsers = [];
            this.userSessionService.FillUserSessionData(this.loggedInUserDetails, null, 0);
          } else {
            if (this.loggedInUserDetails.UserDetails.StorageFacilities.length > 0 && this.loggedInUserDetails.UserDetails.StorageFacilities) {
              this.userSessionService.FillUserSessionData(this.loggedInUserDetails, null, 0);
            } else {
              this.noStoresFound.config = { backdrop: 'static' };
              this.noStoresFound.show();
            }
          }
        }
      }
    }, err => {
      let loginHintData = ' ';
      let signUpSignIn: RedirectRequest | PopupRequest = {
        authority: this.b2cPolicies.authorities.signUpSignIn.authority,
        scopes: []
      };
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...signUpSignIn, loginHint: loginHintData } as RedirectRequest);
      } else {
        this.authService.loginRedirect({ ...signUpSignIn, loginHint: loginHintData });
      }
    });
  }

  getCheckDayEndInProgress(): void {
    this.userService.getCheckDayEndInProgress(0).subscribe((response: IApiResponse) => {
      this.processDayEnd = response;
      this.userSessionService.dayEndEmitter.emit(this.processDayEnd);
      if (this.processDayEnd) {
        this.warning.config = { backdrop: 'static', keyboard: false };
        this.warning.show();
      } else if (!this.processDayEnd) {
        this.updateLoginDate();
        this.getLoggedInUserDetails();
        this.appInsightService.logEvent('SSM-Application Loaded ');
      }
    });
  }

  onClear(flag): void {
    this.authService.logout();
    if (flag == 'authenticationError') {
      this.authenticationError.hide();
    } else if (flag === 'DayEnd') {
      this.warning.hide();
    } else if ('GeoLocation') {
      this.geoLocationWarning.hide();
      this.storeNotFound.hide();
      this.noStoresFound.hide();
    }
  }

  getGeoLocationDetails() {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(resp => {
          resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
          this.showPosition(resp);
        }, err => {
          if (err.code == 1) {
            this.geoLocationWarning.config = { backdrop: 'static' };
            this.geoLocationWarning.show();
          }
          reject(err);

        },
          { maximumAge: 0 }
        );
      } else {
        this.geoLocationWarning.config = { backdrop: 'static' };
        this.geoLocationWarning.show();
      }
    });
  }

  showPosition(position): void {
    this.currentLat = position.coords.latitude;
    this.currentLong = position.coords.longitude;
    if (this.currentLat == null || this.currentLat == undefined || this.currentLat == 0) {
      this.userSessionService.FillUserSessionData(this.loggedInUserDetails, null, 0);
    } else {
      this.getStorageFacilityByLatitudeAndLongitude();
    }
  }

  getStorageFacilityByLatitudeAndLongitude(): void {
    this.userService.getStorageFacilityByLatitudeAndLongitude(this.currentLat, this.currentLong).subscribe((response: IGeoLocationLoginModel) => {
      const geoLocatiBasedonLogInDetails = response;
      console.log(geoLocatiBasedonLogInDetails);
      if (response) {
        this.userSessionService.FillUserSessionData(this.loggedInUserDetails, geoLocatiBasedonLogInDetails, 0);
      } else {
        this.storeNotFound.config = { backdrop: 'static' };
        this.storeNotFound.show();
      }
    });
  }

  updateAppVersion(): void {
    this.versionService.switchToNewVersion();
    this.appNewVersion.hide();
  }

  allAssignedStoresList() {
    if (this.loggedInUserDetails.UserDetails.StoreGroupUsers.length) {
      this.loggedInUserDetails.UserDetails.StoreGroupUsers.map((item) => {
        if (item.StorageFacilities.length) {
          item.StorageFacilities.map((ele) => {
            let index = this.loggedInUserDetails.UserDetails.StorageFacilities.findIndex(o => o.Id == ele.Id);
            if (index == -1) {
              this.loggedInUserDetails.UserDetails.StorageFacilities.push(ele);
            }
          })
        }
      });
    }
  }

  clearCache() {
    this.cacheService.clearCacheByUrl('/api/NotificationSettings/GetNotificationSettingsByUser').subscribe(() => { });
  }
}

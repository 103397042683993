import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdmDashBoardComponent } from '../admin/adm-dashboard/adm-dashboard.component';

const routes: Routes = [ 
  {
    path: 'admin',
    loadChildren: () => import('../admin/adm-dashboard/adm.module').then(m => m.AdmModule),
  }
];

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})

export class SharedRoutingModule {
}

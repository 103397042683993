import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppInsightsService } from '../app-insights.service';
import { ISaveDeleteResponse, ICalltrackerModel, ICallTrackerPhoneCallList, ICallTrackerFilter, ICallRecordingModel, ICallTrackerCallVolumeInputModel, ICallTrackerCallVolumeOutputModel, ICallTrackerReportListOutputModel, ICallTrackerFiletrsOutputModel } from 'src/app/Shared/interface';
import {AppService} from '../app.service';

@Injectable({
  providedIn: 'root'
})

export class CalltrackerService {
  constructor(private http: HttpClient,
              private appinsights: AppInsightsService,
              private appService: AppService) { }

  private baseURL = this.appService.config.apiBaseUrl;


  GetCallSid(fromNumber: string): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Calltracker/GetCallSid?fromphonenumber=${fromNumber}`,
      { observe: 'response' }).pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  GetPhoneCallList(calltrackerFilter: ICallTrackerFilter): Observable<ICallTrackerPhoneCallList[]> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Calltracker/GetPhoneCallList`;
    return this.http.post<ICallTrackerPhoneCallList[]>(url, calltrackerFilter, options)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  GetPhonecall(phonecallId: string): Observable<ICalltrackerModel> {
    return this.http.get<ICalltrackerModel>(
      `${this.baseURL}/Calltracker/GetPhonecall?phonecallId=${phonecallId}`,
      { observe: 'response' }).pipe(
        map(res => {
          return res.body as ICalltrackerModel;
        }),
        catchError(this.handleError)
      );
  }

  SavePhoneCall(callTrackerModel): Observable<ISaveDeleteResponse> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Calltracker/SavePhoneCall`;
    return this.http.post<ISaveDeleteResponse>(url, callTrackerModel, options)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(this.handleError)
      );
  }


  UpdatePhoneCallDuration(callTrackerModel: ICallRecordingModel): Observable<ISaveDeleteResponse> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Calltracker/UpdatePhoneCallDuration`;
    return this.http.post<ISaveDeleteResponse>(url, callTrackerModel, options)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  getActiveSession(phoneNo: string): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Calltracker/GetActiveSession?phoneNo=${phoneNo}`,
      { observe: 'response' }).pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  pauseAndPlaySession(recordState: string, phoneSID: string): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Calltracker/PlayPauseRecording?rcdState=${recordState}&phoneCallSID=${phoneSID}`,
      { observe: 'response' }).pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  getMediaPlayerURL(phoneNo: string,phoneSystem: number, callSID: string,callRecordingIntegrationId: string): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Calltracker/GetMediaPlayerURL?phoneNo=${phoneNo}&phoneSystem=${phoneSystem}&callSID=${callSID}&callRecordingIntegrationId=${callRecordingIntegrationId}`,
      { observe: 'response' }).pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  UpdatePhoneCall(phoneCallId: number): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Calltracker/UpdatePhoneCall?phoneCallId=${phoneCallId}`,
      { observe: 'response' }).pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  UpdateBatchCallDuration(): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Calltracker/UpdateBatchCallDuration`,
      { observe: 'response' }).pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    console.error('server error:', error);
    if (error.error instanceof Error) {
      const errMessage = error.error.message;
      this.appinsights.logEvent(errMessage);
      return throwError(errMessage);
    }
    return throwError(error || 'Node.js server error');
  }

  GetMappingNumber(phoneNo: string): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/adsource/GetAdsourceDetialsFromMappingNumber?MappedNumber=${phoneNo}`,
      { observe: 'response' }).pipe(
        map(res => {
          return res.body as any;
        }),
        catchError(this.handleError)
      );
  }

  GetCallTrackerCallVolume(CallTrackerCallVolumeInput: ICallTrackerCallVolumeInputModel): Observable<ICallTrackerCallVolumeOutputModel[]> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Calltracker/GetCallTrackerCallVolume`;
    return this.http.post<ICallTrackerCallVolumeOutputModel[]>(url, CallTrackerCallVolumeInput, options)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  GetCallTrackerCallStatus(CallTrackerCallVolumeInput: ICallTrackerCallVolumeInputModel): Observable<ICallTrackerCallVolumeOutputModel[]> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Calltracker/GetCallTrackerCallStatus`;
    return this.http.post<ICallTrackerCallVolumeOutputModel[]>(url, CallTrackerCallVolumeInput, options)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  GetCallTrackerCallReports(CallTrackerCallVolumeInput: ICallTrackerCallVolumeInputModel): Observable<ICallTrackerReportListOutputModel[]> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Calltracker/GetCallTrackerCallReports`;
    return this.http.post<ICallTrackerReportListOutputModel[]>(url, CallTrackerCallVolumeInput, options)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  GetCallTrackerFilters(): Observable<ICallTrackerFiletrsOutputModel[]> {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const url = `${this.baseURL}/Calltracker/GetCallTrackerFilters`;
    return this.http.post<ICallTrackerFiletrsOutputModel[]>(url, options)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

}

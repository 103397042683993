import {Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class BaseComponent implements OnDestroy {
  unsubscribeOnDestroy$: Subject<boolean> = new Subject();

  ngOnDestroy(): void {
    this.unsubscribeOnDestroy$.next(true);
    this.unsubscribeOnDestroy$.complete();
    this.unsubscribeOnDestroy$ = null;
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { MasterComponent } from './master/master.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS  } from '@angular/common/http';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { FormsModule , ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppInsightsService } from './services/app-insights.service';
import { TempDataService } from './Shared/tempData.service';
import { ToastrModule } from 'ngx-toastr';
import { ErrorPageComponent } from './Shared/ErrorPage/ErrorPage.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CustomPreloadingStrategy } from './Shared/custom-preloading';
import { SharedModule } from './Shared/shared.module';
import {ServiceWorkerModule} from '@angular/service-worker';
import {VersionService} from './services/version.service';
import { CrcInterceptor } from './crc.interceptor';
import {FacOpsDataService} from './services/operations/facOpsDataService.service';
import { MsalGuard, MsalBroadcastService, MsalService, MsalModule, MsalInterceptor } from '@azure/msal-angular';
import { AzureMapsModule } from 'ng-azure-maps'
import * as atlas from 'azure-maps-control';

// Logger callback for MSAL
export function loggerCallback(logLevel, message, piiEnabled) {
  // console.log(message);
}


@NgModule({
  declarations: [
    AppComponent,
    MasterComponent,
    ErrorPageComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    PaginationModule,
    FormsModule,
    ReactiveFormsModule,
    MsalModule,
    ToastrModule.forRoot({preventDuplicates: true}),
    ModalModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: true}),
    SharedModule,
    AzureMapsModule.forRoot({
      authOptions: {
        authType: atlas.AuthenticationType.subscriptionKey,
        subscriptionKey: 'PLBg9m-FRGdbQcCsUCpauDnU3K4GtXxWU7jdvynpqUU'
      }
    })
  ],
  providers: [
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CrcInterceptor,
      multi: true,
    },
    AppInsightsService,
    TempDataService,
    VersionService,
    CustomPreloadingStrategy,
    FacOpsDataService
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }

import {ApplicationRef, Inject, Injectable} from '@angular/core';
import {first, last, switchMap, tap} from 'rxjs/operators';
import {Observable, concat, merge} from 'rxjs';
import {AppConfig} from '../Shared/app-config.inject';
import {IAppConfig} from '../interfaces/app-config.interface';

@Injectable({
  providedIn: 'root',
})
export class AppService {

  /**
   * Emits an event when app is ready
   */
  isStable$ = this.appRef.isStable.pipe(
    tap((isStable) => console.info('AppService', 'isStable', isStable)),
    first(isStable => isStable === true),
  );

  get config(): IAppConfig {
    return this.appConfig;
  }

  constructor(private readonly appRef: ApplicationRef,
              @Inject(AppConfig) private readonly appConfig: IAppConfig) {}

  /**
   * Allow the app to stabilize first, before starting timed operations with `timer()` and `interval()`
   */
  waitForStable$(...timers: Observable<any>[]): Observable<any> {
    return this.isStable$.pipe(
      switchMap(() => merge(...timers))
    );
  }
}

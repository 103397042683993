import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-error-page',
    templateUrl: 'ErrorPage.component.html',
    styleUrls: ['ErrorPage.component.scss']
})
export class ErrorPageComponent implements OnInit {
    constructor() { }

    ngOnInit(): void { }
}

import { Injectable } from '@angular/core';
import {Observable, of, timer} from 'rxjs';

import { PreloadingStrategy, Route } from '@angular/router';
import {AppService} from '../services/app.service';
import {flatMap} from 'rxjs/operators';

@Injectable()
export class CustomPreloadingStrategy implements PreloadingStrategy {

  constructor(private appService: AppService) {}

  preload(route: Route, loadRoute: () => Observable<any>): Observable<any> {
    if (route.data && route.data['preload']) {
      const delay: number = route.data['delay'];
      return this.appService.waitForStable$(timer(delay)).pipe(
        flatMap(_ => {
          return loadRoute();
        }));
    } else {
      return of(null);
    }
  }
}

import { IApiResponse } from 'src/app/Shared/interface';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
    IMailServerSettingModel, ITrustPilotSettingListModel,
    IAliasEmailSettingListModel, IStorageFacilityListModel,  IMailServerSettingsModels, IAvailableStores, IPhoneSystemTypeModel
} from '../Shared/interface2';
import { AppInsightsService } from './app-insights.service';
import {AppService} from './app.service';


@Injectable({
    providedIn: 'root',
})

export class MailServerService {
    private baseURL = this.appService.config.apiBaseUrl;


    private options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    constructor(private http: HttpClient,
                private appinsights: AppInsightsService,
                private appService: AppService) { }

    getMailServerSettingDetails(): Observable<IMailServerSettingModel> {
        return this.http.get<IMailServerSettingModel>(
            `${this.baseURL}/MailServerSetting/GetMailServerSettingDetails`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const MailServerList = res.body as IMailServerSettingModel;
                    return MailServerList;
                }),
                catchError(this.handleError)
            );
    }

    getTrustPilotSettingsList(): Observable<ITrustPilotSettingListModel[]> {
        return this.http.get<ITrustPilotSettingListModel[]>(
            `${this.baseURL}/MailServerSetting/GetTrustPilotSettingsList`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const TrustPilotList = res.body as ITrustPilotSettingListModel[];
                    return TrustPilotList;
                }),
                catchError(this.handleError)
            );
    }

    getAvailableStores(): Observable<IAvailableStores[]> {
        return this.http.get<IAvailableStores[]>(
            `${this.baseURL}/MailServerSetting/getAvailableStores`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const TrustPilotList = res.body as IAvailableStores[];
                    return TrustPilotList;
                }),
                catchError(this.handleError)
            );
    }

    getAvailableStoresForAliasEmail(): Observable<IAvailableStores[]> {
        return this.http.get<IAvailableStores[]>(
            `${this.baseURL}/MailServerSetting/GetAvailableStoresForAliasEmail`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const TrustPilotList = res.body as IAvailableStores[];
                    return TrustPilotList;
                }),
                catchError(this.handleError)
            );
    }

    getAliasEmailSettingsList(): Observable<IAliasEmailSettingListModel[]> {
        return this.http.get<IAliasEmailSettingListModel[]>(
            `${this.baseURL}/MailServerSetting/GetAliasEmailSettingsList`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const AliasEmailList = res.body as IAliasEmailSettingListModel[];
                    return AliasEmailList;
                }),
                catchError(this.handleError)
            );
    }

    saveMailServerSetting(mailServerDetails: IMailServerSettingsModels): Observable<IApiResponse> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        let url = '';
        if(mailServerDetails.MailServerSetting.MailServerSettingId === 0) {
            url = `${this.baseURL}/MailServerSetting/CreateMailServerSettings`;
        } else {
            url = `${this.baseURL}/MailServerSetting/UpdateMailServerSettings`;
        }
        return this.http.post<IApiResponse>(url, mailServerDetails, options)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.handleError)
            );
    }

    getTrustPilotSettingsFacilityList(Id: number): Observable<IStorageFacilityListModel[]> {
        return this.http.get<IStorageFacilityListModel[]>(
            `${this.baseURL}/MailServerSetting/GetTrustPilotSettingsFacilityList?trustPilotSettingsID=${Id}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const StorageFacilityList = res.body as IStorageFacilityListModel[];
                    return StorageFacilityList;
                }),
                catchError(this.handleError)
            );
    }

    getAliasEmailSettingsFacilityList(Id: number): Observable<IStorageFacilityListModel[]> {
        return this.http.get<IStorageFacilityListModel[]>(
            `${this.baseURL}/MailServerSetting/GetAliasEmailSettingsFacilityList?aliasEmailSettingsID=${Id}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const StorageFacilityList = res.body as IStorageFacilityListModel[];
                    return StorageFacilityList;
                }),
                catchError(this.handleError)
            );
    }

    // new sms settings methods
    getPhoneNumbersList(serviceType, serviceOption): Observable<any[]> {
        return this.http.get<any[]>(
            `${this.baseURL}/MailServerSetting/GetPhoneNumbersByServiceType?serviceType=${serviceType}&serviceOption=${serviceOption}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const StorageFacilityList = res.body as any[];
                    return StorageFacilityList;
                }),
                catchError(this.handleError)
            );
    }

    getStoresList(serviceType, serviceOption): Observable<any[]> {
        return this.http.get<any[]>(
            `${this.baseURL}/MailServerSetting/GetLocationByServiceType?serviceType=${serviceType}&serviceOption=${serviceOption}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const StorageFacilityList = res.body as any[];
                    return StorageFacilityList;
                }),
                catchError(this.handleError)
            );
    }

    getAssignedPhoneList(serviceType, serviceOption): Observable<any[]> {
        return this.http.get<any[]>(
            `${this.baseURL}/MailServerSetting/GetSMSPhoneNumberList?serviceType=${serviceType}&serviceOption=${serviceOption}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const StorageFacilityList = res.body as any[];
                    return StorageFacilityList;
                }),
                catchError(this.handleError)
            );
    }

    checkPhoneSystemTypes(): Observable<IPhoneSystemTypeModel> {
        return this.http.get<IPhoneSystemTypeModel>(
            `${this.baseURL}/MailServerSetting/CheckPhoneSystemTypes`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const phoneSystem = res.body as IPhoneSystemTypeModel;
                    return phoneSystem;
                }),
                catchError(this.handleError)
            );
    }

    private handleError(error: HttpErrorResponse) {
        console.error('server error:', error);
        if (error.error instanceof Error) {
            const errMessage = error.error.message;
            this.appinsights.logEvent(errMessage);
            return throwError(errMessage);
        }
        return throwError(error || 'Node.js server error');
    }

}

/* tslint:disable:no-console */
import {ApplicationRef, Injectable} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {HttpClient} from '@angular/common/http';
import {Md5} from 'ts-md5';
import {AppService} from './app.service';
import {from, interval} from 'rxjs';
import {startWith, switchMap, tap} from 'rxjs/operators';
import {random} from 'lodash-es';
import * as moment from 'moment';
import {Moment} from 'moment';

@Injectable()
export class VersionService {
  private readonly _config = Object.freeze({
    serviceName: 'VersionUpdate', // Is used for debug purpose in the console
    pollingInterval: 10 * 60 * 1000, // 30m
    reloadMaxAppRunningTime: 10, // During this time (in seconds) after start app would be reloaded immediately without a notification
  });
  private _newVersionAvailableCallback: Function;
  private _appHash: string | Int32Array;
  private readonly _appInitMoment: Moment = moment();

  constructor(
    private readonly appService: AppService,
    private readonly appRef: ApplicationRef,
    private readonly http: HttpClient,
    private readonly updates: SwUpdate
  ) {
    this.updates.activated.subscribe(this._onActivated.bind(this));
  }

  startVersionPolling(newVersionAvailableCallback: Function): void {
    if (!this.updates.isEnabled) {
      console.warn(this._config.serviceName, 'Service Workers are turned off or not supported by a browser');
      return;
    }

    const checkDuration = moment.duration(this._config.pollingInterval);
    const nextCheckDate = moment().add(checkDuration.minutes(), 'm').format();
    console.log(
      this._config.serviceName,
      `New version polling has started. Next check in ${checkDuration.minutes()}m, at ${nextCheckDate}`
    );
    this._newVersionAvailableCallback = newVersionAvailableCallback;
    const pollingInterval$ = interval(this._config.pollingInterval);
    this.appService.waitForStable$(pollingInterval$).pipe(
      startWith(null),
      tap(() => console.info(this._config.serviceName, 'Checking for a new version started')),
      switchMap(() => from(this.updates.checkForUpdate()))
    ).subscribe(() => {
        this._checkNewVersion(); // Custom new version check
        console.info(this._config.serviceName, 'Checking for a new version finished');
      });
  }

  /**
   * Activates new version of the app and reloads the page
   */
  switchToNewVersion(): void {
    console.info(this._config.serviceName, 'Start switching to a new app version...');
    this.updates.activateUpdate().then(() => document.location.reload());
  }

  /**
   * Check app new version by comparing cached ngsw.json with a server one.
   */
  private _checkNewVersion(): void {
    this.http.get('/ngsw.json?no-cache=' + random(100000)).subscribe((data: any) => {
      const hash = Md5.hashStr(JSON.stringify(data.hashTable));
      console.info(this._config.serviceName, this._appHash, hash);
      if (!this._appHash) {
        this._appHash = hash;
        return;
      }
      if (this._appHash !== hash) {
        this._appHash = hash;
        this._onAvailable();
      }
    });
  }

  /**
   * Handles upgrade to a newer version of the app
   * Switches immediately if app has been started recently
   * Shows a prompt if app is already running for some time
   */
  private _onAvailable(): void {
    console.info(this._config.serviceName, 'New app version available!', event);
    const appRunningTime = moment().diff(this._appInitMoment, 'seconds');

    // Switch to a newer version immediately if app has been started recently
    if (appRunningTime < this._config.reloadMaxAppRunningTime) {
      this.switchToNewVersion();
      return;
    }

    console.info(this._config.serviceName, 'Switch to a new version modal was shown', `Running time: ${appRunningTime}`);
    if (this._newVersionAvailableCallback) {
      this._newVersionAvailableCallback();
    }
    this.appRef.tick();
  }

  /**
   * Is called AFTER upgrade to a new version
   */
  private _onActivated(): void {
    console.info(this._config.serviceName, 'Switched to a new app version!', event);
  }
}

import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { UserService } from 'src/app/services/user.service';
import { UserSessionService } from 'src/app/services/usersession.service';
import { UserLoginService } from './user.login.service';
import { FingerPrintService } from 'src/app/services/fingerPrint/fingerprint.service';
import { take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-user-login',
    templateUrl: './user-login.component.html',
    styleUrls: ['./user-login.component.scss']
  })

  export class UserloginComponent implements OnInit {
    userName = '';
    password = '';
    fingerPrintInit: any;
    loginError = false;
  fingerPrintObject: { UserId: number; ImageData: any; Data: any; CbeffId: any; Width: any; Height: any; Resolution: any; Initials: any; Pin: string; };
  isFingerprintAcquired: boolean;
  observerCounter: number = 0;
  isFingerPrintEnabled = environment.isFingerPrintEnabled;
    constructor(public loginService: UserLoginService,
      public userSessionService: UserSessionService,
      public authService: MsalService,
      public userService: UserService,
      public fingerPrintService: FingerPrintService,
      private toaster: ToastrService) { }

      toasterConfig = {
        closeButton: true,
        timeOut: 4500,
        easing: 'ease-out',
        progressBar: true,
        positionClass: 'toast-top-right',
        tapToDismiss: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'slideUp'
      };
    ngOnInit() {
    
      
      // this.fingerPrintInit.startCapture();

      this.fingerPrintService.captureEventEmitter.subscribe((obj) => {
        let fingerObj = {
            UserId: 0,
            ImageData: obj.ImageData,
            Data: obj.Data,
            CbeffId: obj.CbeffId,
            Width: obj.width,
            Height: obj.height,
            Resolution: obj.resolution,
            Initials: null,
            Pin: null
        }

       
        this.fingerPrintObject = fingerObj;
       if(this.fingerPrintService.moduleType == 1 && this.observerCounter == 0) 
       this.callCompareFingerEndpoint(fingerObj);

      // this.fingerPrintService.enrollFingerEndpoint(this.fingerPrintObject).subscribe((response) => {
               
      //   // if(response && this.isEditUser) {
      //   //     this.toastr.success('Finger print enrolled successfully', 'Success');
      //   // }
        
      //   console.log('response', response);
      //   });
    
    });
    }

    callCompareFingerEndpoint(fingerObj) {
      this.observerCounter++;
      this.fingerPrintService.compareFingerEndpoint(fingerObj).subscribe((response:any) => {
        console.log('response', response);
        this.observerCounter = 0;
        if(response.Success) {
          this.isFingerprintAcquired = true;
          this.fingerPrintService.userId = response.Id;
          this.fingerPrintService.fingerPrintLoginUserId = response.Id;
          this.fingerPrintService.userGroupId = response.UserGroupId;
          this.fingerPrintService.isFingerPrintLogin = true;
          this.userService.fingerPrintUserId = response.Id;
          this.userService.isFingerPrintLogin = true;
          this.getTokenForPrint();
        } else {
          this.isFingerprintAcquired = false;
          this.toaster.error('No match found!', 'Error', this.toasterConfig);
        }
      
    }, err=>{
      this.toaster.error('No match found!', 'Error', this.toasterConfig);
      this.observerCounter = 0;
    });
    }

    getTokenForPrint() {
      this.loginService.getToken().subscribe((resp)=>{
        // if(!this.userService.bearerToken) {
          this.userService.bearerToken = resp.AccessToken;
          this.userSessionService.userLoginEmitter.emit(resp);
         // this.fingerPrintService.moduleType = 0;
           localStorage.setItem('beareerToken',this.userService.bearerToken);
          console.log(resp);
       // }
       
      });
    }
    onClickLogin() {
      this.loginError = false;
      this.loginService.getUserLogin(this.userName, this.password).subscribe((response)=>{
        console.log(response);
        if(response) {       
          
          this.userService.bearerToken = response.AccessToken;
          this.userSessionService.userLoginEmitter.emit(response);
           localStorage.setItem('beareerToken',this.userService.bearerToken);
          //  this.authService.updateDataFromCache(obj.Scopes);
          // this.authService.acquireTokenSilent(accessTokenRequest.scopes,this.authService.authority).then((res)=>{
          //   console.log(res)
          // }).catch((err) => 
          // console.log(err)
          // );
        } else{
          this.loginError = true;
        }
      }, err=>{
        if(err)
        this.loginError = true;
      });
    }

    forgotPassword() {
     //let defaultPolict = 'B2C_1A_ForcePasswordReset_signup_signin_esoft2'.toLowerCase();

      // this.authService.authority = this.authService.authority.replace('B2C_1A_ForcePasswordReset_signup_signin_esoft2'.toLowerCase(), 'B2C_1A_PasswordReset_SignInName_OtherEmail_PassReset'.toLowerCase());
      
      // this.authService.loginRedirect();
    }
  }
import { Injectable } from '@angular/core';

@Injectable()
export class TempDataService {
  storeList = [];
  merchandiseList = [];
  merchandisePackList = [];
  usersList = [];
  truckRentalList = [];
  allUnitsList = [];
  unitTypeList = [];
  feesAndChargesList = [];
  filterReprint = [];
  marketingList = [];
  callScriptFormList =[];
  Followupstorage: any;
  eSignatureReviewUrl: string;
  storeListForReports = [];
  notificationList = [];
 }

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, OnInit, Output } from '@angular/core';
import { exhaust, share, take } from 'rxjs/operators';
import { EPaymentOrigin } from 'src/app/Shared/enum';
import { environment } from 'src/environments/environment';
import { UserSessionService } from '../usersession.service';
import {AppInsightsService} from '../app-insights.service';
import {AppService} from '../app.service';
declare var Fingerprint: any;
@Injectable({
    providedIn: 'root'
})
export class FingerPrintService   {
    fingerPrint;
    statusMessage="";
    sdk;
    myVal = '82986309-AC61-C247-888E-5B9942';
    userId = 1;
    fingerPrintLoginUserId = 1;
    userGroupId =1;
    @Output() captureEventEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() captureQuality: EventEmitter<any> = new EventEmitter<any>();
    @Output() captureMessage: EventEmitter<any> = new EventEmitter<any>();
    @Output() checkForDevice: EventEmitter<any> = new EventEmitter<any>();
    public baseURL = this.appService.config.apiBaseUrl;
    options = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'secondary_userId' : `${this.userId}` }) };
    miscSettings: any;

    //Flags for the payment
    paymentOrigin:number = 0;
    isFingerPrintUsed = false;
    isFingerPrintLogin = false;
    moduleType = 1;   // 1 => login , 2 => Popup

    fingerPrintInit;
    constructor(public http: HttpClient,
                public userSessionService: UserSessionService,
                private appService: AppService) {}

      miscFacilityId = this.userSessionService.userSessionData.LastAccessedStoreId;

  FingerprintSdkTest = (function (tempInstance) {
    function FingerprintSdkTest(tempInstance) {
      var _instance = this;
      this.operationToRestart = null;
      this.acquisitionStarted = false;
      this.sdk = new Fingerprint.WebApi;


      var message = "";
      this.sdk.onDeviceConnected = function (e) {
        // Detects if the deveice is connected for which acquisition started
        tempInstance.showMessage('Scan your finger',1);
      };
      this.sdk.onDeviceDisconnected = function (e) {
        // Detects if device gets disconnected - provides deviceUid of disconnected device
        tempInstance.showMessage('Device disconnected',2);
      };
      this.sdk.onCommunicationFailed = function (e) {
        // Detects if there is a failure in communicating with U.R.U web SDK
        tempInstance.showMessage('Communinication Failed',3);
      };


      // this.sdk.enumerateDevices = function (e) {
      //   // Detects if there is a failure in communicating with U.R.U web SDK
      //  console.log('em devices',e);
      // };

      var devices = this.sdk.enumerateDevices();
      devices.then(function (sucessObj) {
        tempInstance.getDeviceInfo(sucessObj);
    }, function (error){
      tempInstance.getDeviceInfo(error);
      console.log('em devices', error);
    });

      this.sdk.onSamplesAcquired = function (s) {
        console.log('This is the content of s: ', JSON.stringify(s));


        // Sample acquired event triggers this function
        var samples = JSON.parse(s.samples);

        //var sampleData = Fingerprint.b64UrlTo64(samples[0].Data);
        var sampleData = samples[0].Data;
        console.log('This is the sampleData1: ', JSON.stringify(sampleData));

        var decodedData = JSON.parse(Fingerprint.b64UrlToUtf8(sampleData));

        console.log('Decoded Data sample : ', JSON.stringify(decodedData));

        var imageUTF8 = decodedData.Data;
        var imagebase64 = Fingerprint.b64UrlTo64(imageUTF8);
        var FPwidth = decodedData.Format.iWidth;
        var FPheight = decodedData.Format.iHeight;
        var FPresolution = decodedData.Format.iXdpi;

        localStorage.setItem("raw", Fingerprint.b64UrlTo64(decodedData.Data));

        var formData = new FormData();
        formData.append("ImageData", imagebase64);
        formData.append("Data", samples[0]);
        formData.append("CbeffId", '0');
        formData.append("width", FPwidth);
        formData.append("height", FPheight);
        formData.append("resolution", FPresolution);

        let obj = {
         ImageData: imagebase64,
         Data: samples[0],
         CbeffId: 0,
         width: FPwidth,
         height: FPheight,
         resolution: FPresolution
        }

        tempInstance.enroll(obj);


      };
      this.sdk.onQualityReported = function (e) {
        // Quality of sample aquired - Function triggered on every sample acquired
        tempInstance.reportQuality(Fingerprint.QualityCode[(e.quality)]);
      }
      this.statusMessage = message;
    }


    FingerprintSdkTest.prototype.startCapture = function () {
      if (this.acquisitionStarted) // Monitoring if already started capturing
        return;
      var _instance = this;
      this.operationToRestart = this.startCapture;
      this.sdk.startAcquisition(Fingerprint.SampleFormat.Raw, _instance.myVal).then(function () {
        _instance.acquisitionStarted = true;
        //Disabling start once started
      }, function (error) {
        _instance.statusMessage = error.message;
      });
    };
    FingerprintSdkTest.prototype.stopCapture = function () {
      if (!this.acquisitionStarted) //Monitor if already stopped capturing
        return;
      var _instance = this;
      this.sdk.stopAcquisition().then(function () {
        _instance.acquisitionStarted = false;
        //Disabling stop once stoped
      }, function (error) {
        _instance.statusMessage = error.message;
      });
    };

    FingerprintSdkTest.prototype.getInfo = function () {
      var _instance = this;
      return this.sdk.enumerateDevices();
    };

    FingerprintSdkTest.prototype.getDeviceInfoWithID = function (uid) {
      var _instance = this;
      return this.sdk.getDeviceInfo(uid);
    };


    return FingerprintSdkTest;
  })();

  getDeviceInfo(obj) {
    this.checkForDevice.emit(obj);
  }

  enroll(obj) {
    this.captureEventEmitter.emit(obj);
  }

  reportQuality(qlty) {
    this.captureQuality.emit(qlty);
  }

  enrollFingerEndpoint(obj) {
    let url = `${this.baseURL}/FingerPrintScanner/EnrollmentScanner`;
   return this.http.post(url, obj);
  }

  showMessage(msg,type) {
    let obj = {
      type: type,
      message: msg
    }
    this.captureMessage.emit(obj);
  }

  compareFingerEndpoint(obj) {

    let url = `${this.baseURL}/FingerPrintScanner/CaptureAndExtractFmd`;
    return this.http.post(url, obj, this.options).pipe(take(1));
  }


}
